import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement";
import {TexturesFactory} from "../../textures/TexturesFactory.js";

export class ProgressElement extends AbstractCustomElement {
	attrs = {
		width: 120,
		value: 0,
		color: 'info',
	};

	#progressWidth = 0;

	renderAttribute(name) {
		if (name === 'value') {
			const valueWidth = this.#getValueWidth();
			this.style.setProperty('--value-width', `${valueWidth}px`);
			return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '<div class="bg"><div class="light"></div></div><div class="glow"></div>';
		await this.#appendStyle();
	}

	async #appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();
		const lightColors = Style.getColorsSet(this.attrs.color);

		const progressIndent = 2;
		const progressStepWidth = Math.floor((this.attrs.width - progressIndent * 2) / 10);
		const progressWidth = progressStepWidth * 10;
		const fullWidth = progressWidth + progressIndent * 2;
		this.#progressWidth = progressWidth;

		const valueWidth = this.#getValueWidth();

		style.textContent = /* css */`
			:host {
				--value-width: ${valueWidth}px;
				display: inline-flex;
				width: ${fullWidth}px;
				height: 16px;
				border-radius: 8px;
				box-sizing: border-box;
				position: relative;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});
			}

			:host::before {
				content: '';
				position: absolute;
				inset: 1px 1px 1px 1px;
				border-radius: 7px;
				background-color: ${colors.bgDark2};
				z-index: 1;
			}

			.bg {
				position: absolute;
				top: ${progressIndent}px;
				left: ${progressIndent}px;
				width: ${progressWidth}px;
				height: calc(100% - ${progressIndent * 2}px);
				border-radius: 6px;
				background-image:
					url("${await TexturesFactory.getHorizontalLines(progressStepWidth, colors.bgDark2.cssValue)}"),
					linear-gradient(${colors.bgDark1} 60%, ${colors.bg});
				z-index: 2;
				overflow: hidden;

				&::before {
					content: '';
					position: absolute;
					inset: 0;
					border-radius: 6px;
					background-image: linear-gradient(${colors.bgLight2}, transparent 40%);
					z-index: 1;
				}

				& .light {
					position: relative;
					width: var(--value-width, 0);

					transition: width 0.2s ease-out;
					margin-left: 1px;
					margin-top: 1px;
					height: calc(100% - 2px);
					border-radius: 4px;
					background-color: ${lightColors.saturated};
					background-image:linear-gradient(#ffffffaa, transparent 40%);
					box-shadow: 0 0 ${progressIndent + 1}px ${lightColors.saturated},
						0 0 3px #ffffff inset;
					z-index: 2;
				}
			}

			.glow {
				position: absolute;
				top: ${progressIndent + 1}px;
				left: ${progressIndent + 1}px;
				width: var(--value-width, 0);
				transition: width 0.2s ease-out;
				border-radius: 4px;
				height: calc(100% - ${progressIndent * 2 + 2}px);
				z-index: 2;
				box-shadow: 0 0 5px 1px ${lightColors.bgLight2};
			}
		`;

		this.shadowRoot.appendChild(style);
	}

	#getValueWidth() {
		return Math.floor(Math.min(100, Math.max(0, this.attrs.value)) / 100 * (this.#progressWidth - 2));
	}
}

customElements.define('f-progress', ProgressElement);
