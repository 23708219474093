import {Color} from "../styles/Color.js";
import {Style} from "ui/components/styles/Style";
import {AbstractTexture} from "./AbstractTexture.js";

/**
 * @typedef {object} NoiseTextureOptions
 * @property {string|Color} [color]
 * @property {number} [minAlpha]
 * @property {number} [maxAlpha]
 */

export class NoiseTexture extends AbstractTexture {
	/** @type {NoiseTextureOptions} */
	#options = {
		color: '',
		minAlpha: 0.1,
		maxAlpha: 0.4,
	};

	constructor() {
		super();

		const colorsSet = Style.getColorsSet();
		this.#options.color = colorsSet.bgLight1;
	}

	/**
	 * @param {NoiseTextureOptions} options
	 * @returns {NoiseTexture}
	 */
	setOptions(options) {
		Object.assign(this.#options, options);
		return this;
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 * @param {number} height
	 */
	renderTexture(ctx, width, height) {
		const points = width * height * 4;
		const arr = new Uint8ClampedArray(points);
		const [r, g, b] = this.#options.color instanceof Color
			? this.#options.color.rgb
			: Color.createFromRgb(this.#options.color).rgb;

		const alphaDiff = this.#options.maxAlpha - this.#options.minAlpha;

		let i = 0;
		while (i < points) {
			arr[i++] = r;
			arr[i++] = g;
			arr[i++] = b;
			arr[i++] = ((this.#options.minAlpha + Math.random() * alphaDiff) * 255) | 0;
		}

		const imageData = new ImageData(arr, width);
		ctx.putImageData(imageData, 0, 0);
	}
}
