import {AbstractCustomElement} from "ui/components/elements/AbstractCustomElement";
import {Style} from "ui/components/styles/Style";
import {escapeHtml} from "ui/helpers/formatters";

class ChangesBarItemElement extends AbstractCustomElement {
	attrs = {
		date: '',
		title: '',
		icon: '',
		new: false,
		interactive: false,
		link: '',
		selected: false,
	}

	/** @type {HTMLElement} */
	#bulb;

	renderAttribute(name) {
		if (name === 'new' || name === 'selected') {
			this.#updateBulb();
			return true;
		}

		return false;
	}

	async render() {
		const colors = Style.getColorsSet();
		const successColors = Style.getColorsSet('success');

		const style = /* css */`
			:host {
				width: 100%;
				display: block;
				cursor: pointer;
				z-index: 1;
				position: relative;
				padding: 2px 0;
			}

			.box {
				display: flex;
				align-items: center;
				padding: ${Style.getIndentPx(2)};
				gap: ${Style.getIndentPx(1)};
				z-index: 3;
				position: relative;
				border-top: 1px solid ${colors.bgLight1};
				border-bottom: 1px solid ${colors.bgDark1};
				box-shadow:
					0 1px 2px ${colors.bgDark1},
					0 -1px 2px ${colors.bgDark1};
			}

			.date {
				color: ${colors.textDark1};
				font-size: ${Style.getFontSizePx('kbd')};
				text-shadow: -1px 1px 0 ${colors.textShadow};
			}

			.title {
				text-shadow: -1px 1px 0 ${colors.textShadow};
			}

			f-badge {
				margin: 0 ${Style.getIndentPx(1)};
            }

			:host(:where([selected=""], [selected="true"])) {
				z-index: 2;
			}

			:host(:where([selected=""], [selected="true"])) .box {
				border-top: 1px solid color-mix(in srgb, ${colors.bgLight1}, ${successColors.glow} 40%);
				border-bottom: 1px solid color-mix(in srgb, ${colors.bgDark1}, ${successColors.glow} 40%);
				box-shadow:
					0 0 6px color-mix(in srgb, ${successColors.glow}, transparent 50%),
					0 0 3px color-mix(in srgb, ${successColors.glow}, transparent 50%) inset;
				z-index: 4;
			}
		`;

		let icon = '';
		if (this.attrs.icon !== '') {
			const color = {
				'cup': 'default',
				'music': 'success',
				'play': 'warning',
			}[this.attrs.icon];
			icon = `<f-badge color="${color}"><f-icon size="5" icon="${this.attrs.icon}"></f-icon></f-badge>`;
		}

		// language=html
		this.shadowRoot.innerHTML = `
			<style>${style}</style>
			<div class="box">
				<f-bulb
					color="${this.attrs.new ? 'warning' : 'success'}"
					on="${this.attrs.new || this.attrs.selected}"></f-bulb>
				${icon}
				<div>
					<div class="date">${escapeHtml(this.attrs.date)}</div>
					<div class="title">${escapeHtml(this.attrs.title)}</div>
				</div>
			</div>
		`;
	}

	#updateBulb() {
		if (!this.#bulb) {
			this.#bulb = this.shadowRoot.querySelector('f-bulb');
		}

		this.#bulb.setAttribute('on', `${this.attrs.new || this.attrs.selected}`);
		this.#bulb.setAttribute('color', `${this.attrs.new ? 'warning' : 'success'}`);
	}
}

customElements.define('f-changes-bar-item', ChangesBarItemElement);
