var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<f-box flex column justify-content=\"center\" gap=\"3\" padding=\"3\">\n	<div>\n		<f-icon icon=\"warning\" size=\"20\"></f-icon>\n	</div>\n	<div>\n		<f-progress width=\"200\" color=\"danger\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"batteryLevel") || (depth0 != null ? lookupProperty(depth0,"batteryLevel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"batteryLevel","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":64}}}) : helper)))
    + "\"></f-progress>\n	</div>\n	<div style=\"text-align: center\">"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"messages.low-battery",(depth0 != null ? lookupProperty(depth0,"batteryLevel") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":77}}}))
    + "</div>\n	<div>\n		<f-button color=\"warning\" data-role=\"close\" icon=\"check\" caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.continue",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":68},"end":{"line":10,"column":95}}}))
    + "\"></f-button>\n	</div>\n</f-box>\n";
},"useData":true});