import {AbstractCustomElement} from "../AbstractCustomElement.js";

class SlotElement extends AbstractCustomElement {
	attrs = {
		asHtml: false,
		value: '',
	};

	constructor() {
		super(false);
	}

	async render() {
		if (this.attrs.asHtml) {
			this.innerHTML = this.attrs.value;
		} else {
			this.textContent = this.attrs.value;
		}
	}
}

customElements.define('f-slot', SlotElement);
