import {Style} from "ui/components/styles/Style";
import {AbstractTexture} from "./AbstractTexture.js";
/**
 * @typedef {object} WarningBorderTextureOptions
 * @property {string} [bgColor]
 * @property {string} [linesColor]
 * @property {string} [fillColor]
 */

export class WarningBorderTexture extends AbstractTexture {
	/** @type {WarningBorderTextureOptions} */
	#options = {
		bgColor: '',
		linesColor: '',
		fillColor: '',
	};

	constructor() {
		super();

		const colorsSet = Style.getColorsSet('warning');
		this.#options.bgColor = colorsSet.bg.cssValue;
		this.#options.linesColor = '#fff';
	}

	/**
	 * @param {WarningBorderTextureOptions} options
	 * @returns {WarningBorderTexture}
	 */
	setOptions(options) {
		Object.assign(this.#options, options);
		return this;
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 */
	renderTexture(ctx, width) {
		ctx.save();

		const cellWidth = width / 3;
		const radius = cellWidth / 1.5;
		const pos = cellWidth / 3;

		ctx.lineWidth = cellWidth / 2;
		ctx.strokeStyle = this.#options.bgColor || '#F00';
		ctx.beginPath();
		ctx.roundRect(pos - 0.5, pos - 0.5, width - pos * 2, width - pos * 2, radius);
		ctx.stroke();

		if (this.#options.fillColor && this.#options.bgColor !== '') {
			ctx.fillStyle = this.#options.fillColor;
			ctx.beginPath();
			ctx.roundRect(pos - 0.5, pos - 0.5, width - pos * 2, width - pos * 2, radius);
			ctx.fill();
		}

		if (this.#options.linesColor !== '') {
			ctx.strokeStyle = this.#options.linesColor;
			const lineWidth = cellWidth / 3;
			const lineWidthHalf = lineWidth / 2;
			ctx.lineWidth = lineWidth;

			if (this.#options.bgColor === '') {
				ctx.globalCompositeOperation = 'source-in';
			} else {
				ctx.globalCompositeOperation = 'source-atop';
			}

			ctx.beginPath();

			// top-left & bottom-right corners
			ctx.moveTo(-lineWidthHalf, 0);
			ctx.lineTo(width - lineWidthHalf, width);

			// left & bottom lines
			ctx.moveTo(-lineWidthHalf, cellWidth);
			ctx.lineTo(width - cellWidth - lineWidthHalf, width);

			// bottom-left corner
			ctx.moveTo(-lineWidthHalf, cellWidth * 2);
			ctx.lineTo(width - cellWidth * 2 - lineWidthHalf, width);

			// top line
			ctx.moveTo(cellWidth + lineWidthHalf, 0);
			ctx.lineTo(cellWidth * 2 + lineWidthHalf, cellWidth);

			// top-right corner
			ctx.moveTo(width - cellWidth + lineWidthHalf, 0);
			ctx.lineTo(width + lineWidthHalf, cellWidth);

			// right line
			ctx.moveTo(width - cellWidth + lineWidthHalf, cellWidth);
			ctx.lineTo(width + lineWidthHalf, cellWidth * 2);

			ctx.stroke();
		}

		ctx.restore();
	}
}
