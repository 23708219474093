import {createState, deleteState, getState, getStateForElement} from "./state.js";

/** @type {Object.<string,(el:HTMLElement,value:*) => void>} */
const STATE_ATTRS = {
	'hide:if': (el, value) => {
		el.style.display = value ? 'none' : '';
		el.shadowRoot.dispatchEvent(new CustomEvent('f-visibility-change', {detail: !value}));
	},
	'show:if': (el, value) => {
		el.style.display = value ? '' : 'none';
		el.shadowRoot.dispatchEvent(new CustomEvent('f-visibility-change', {detail: !!value}));
	},
	'remove:if': (el, value) => {
		if (value) {
			el.remove();
		}
	},
	'by-window-width': (el, value, attr) => {
		let bestValue = value['max'] || '';
		let minWidth = Number.MAX_VALUE;
		const windowWidth = window.innerWidth;

		for (const [maxWidth, v] of Object.entries(value)) {
			if (maxWidth === 'max' || !Number.isFinite(v)) {
				continue;
			}

			if (windowWidth < maxWidth && maxWidth < minWidth) {
				minWidth = maxWidth;
				bestValue = v;
			}
		}

		if (el.getAttribute(attr) !== bestValue) {
			el.setAttribute(attr, bestValue);
		}
	},
	'if': (el, value, attr) => {
		if (value) {
			el.setAttribute(attr, '');
		} else {
			el.removeAttribute(attr);
		}
	},
	'from': (el, value, attr) => {
		if (value !== null && value !== undefined) {
			el.setAttribute(attr, value);
		} else {
			el.removeAttribute(attr);
		}
	},
};

export class AbstractCustomElement extends HTMLElement {
	attrs = {};

	#renderInProgress = false;
	#renderRequested = false;
	#isFirstRender = true;
	#windowSizeDependant = false;
	#hiddenByWindowSizeRule = false;
	#defaultAttrs;

	/** @type {Object.<string,{expr:string, events: string[], func:()=>void}>|null} */
	#stateAttrEventHandlers = null;

	/** @type {Object.<string,()=>void>|null} */
	#windowSizeAttrHandlers = null;

	/**
	 * @param {boolean} [withShadowDom]
	 * @param {ShadowRootInit} [shadowOptions]
	 */
	constructor(withShadowDom = true, shadowOptions = {}) {
		super();

		if (withShadowDom) {
			this.attachShadow({mode: 'open', ...shadowOptions});
		}
	}

	connectedCallback() {
		if (this.#hasWindowSizeDependantAttrs()) {
			if (!this.#windowSizeDependant) {
				this.#windowSizeDependant = true;
				window.addEventListener('resize', this.#handleWindowResize);
				screen?.orientation?.addEventListener('change', this.#handleWindowResize);
			}

			this.#handleWindowResize();
			if (this.#hiddenByWindowSizeRule) {
				return;
			}
		} else if (this.#windowSizeDependant) {
			this.#stopHandleWindowResize();
		}

		if (this.#isFirstRender || this.#renderRequested) {
			this.#buildElement();
		} else if (this.isConnected) {
			this.#updateStateAttrs();
		}
	}

	disconnectedCallback() {
		this.#stopHandleWindowResize();

		const stateName = this.getAttribute('init-state');
		if (stateName) {
			deleteState(stateName);
		}

		if (this.#stateAttrEventHandlers !== null) {
			for (const [, {events, func}] of Object.entries(this.#stateAttrEventHandlers)) {
				for (const eventName of events) {
					document.removeEventListener(eventName, func);
				}
			}
			this.#stateAttrEventHandlers = null;
		}
	}

	async render() {
		// for overriding
	}

	renderAttribute(name) {
		// for overriding
		return false;
	}

	/**
	 * @param {Event} event
	 */
	dispatchEvent(event) {
		super.dispatchEvent(event);
		const eventName = `on${event.type}`;

		if (typeof this[eventName] === 'function') {
			if (this[eventName](event) === false) {
				event.preventDefault();
			}
		} else if (this.hasAttribute(eventName)) {
			if ((new Function('event', this.getAttribute(eventName))).call(this, event) === false) {
				event.preventDefault();
			}
		}
	}

	#hasWindowSizeDependantAttrs() {
		for (const attr of this.attributes) {
			if (attr.name === 'min-screen-width' || attr.name.indexOf(':by-window-width') > -1) {
				return true;
			}
		}

		return false;
	}

	#buildElement() {
		if (!this.isConnected) {
			return;
		}

		if (!this.#defaultAttrs) {
			this.#defaultAttrs = {...this.attrs};

			new MutationObserver(() => {
				queueMicrotask(this.#renderIfNeeded.bind(this));
			}).observe(this, {attributes: true});
		}

		if (this.#isFirstRender) {
			const initCode = this.getAttribute('init');
			if (initCode) {
				new Function(initCode).call(this);
			}
		}

		this.#updateStateAttrs();

		this.#renderRequested = true;
		void this.#renderIfNeeded();
	}

	#stopHandleWindowResize() {
		if (!this.#windowSizeDependant) {
			return;
		}

		this.#windowSizeDependant = false;
		window.removeEventListener('resize', this.#handleWindowResize);
		screen?.orientation?.removeEventListener('change', this.#handleWindowResize);
	}

	#handleWindowResize = () => {
		if (!this.#hasWindowSizeDependantAttrs()) {
			this.#stopHandleWindowResize();
			return;
		}

		if (this.hasAttribute('min-screen-width') && parseInt(this.getAttribute('min-screen-width'), 10) > window.innerWidth) {
			if (!this.#hiddenByWindowSizeRule) {
				this.#hiddenByWindowSizeRule = true;
				this.style.display = 'none';
			}

			return;
		}

		if (this.#windowSizeAttrHandlers !== null) {
			Object.values(this.#windowSizeAttrHandlers).forEach(f => f());
		}

		if (this.#hiddenByWindowSizeRule) {
			this.#hiddenByWindowSizeRule = false;
			this.style.display = '';

			if (this.#isFirstRender) {
				this.#buildElement();
			}
		}
	};

	#updateStateAttrs() {
		const existingAttrs = [];
		let thisStateName;

		for (const attr of this.attributes) {
			const nameParts = attr.name.split(':');
			if (nameParts.length === 1) {
				continue;
			}

			existingAttrs.push(attr.name);

			let expr = attr.value;
			let attrPrefix = nameParts[0];
			let attrCmd = nameParts[1];
			let attrArgs = nameParts.slice(2);
			let func;

			if (STATE_ATTRS[attr.name]) {
				func = STATE_ATTRS[attr.name];
			} else if (STATE_ATTRS[attrCmd]) {
				func = STATE_ATTRS[attrCmd];
			} else {
				continue;
			}

			if (this.#stateAttrEventHandlers !== null && this.#stateAttrEventHandlers[attr.name]) {
				const stateListener = this.#stateAttrEventHandlers[attr.name];
				if (stateListener.expr === expr) {
					continue;
				}

				for (const eventName of stateListener.events) {
					document.removeEventListener(eventName, stateListener.func);
				}
			}

			if (attr.value === null || attr.value === '') {
				continue;
			}

			if (this.#stateAttrEventHandlers === null) {
				this.#stateAttrEventHandlers = {};
			}

			const handler = new Function('const ___x = ' + expr + '; return ___x;');
			func(this, handler.call(this), attrPrefix, ...attrArgs);

			const stateListener = {
				expr,
				events: [],
				func: () => func(this, handler.call(this), attrPrefix, ...attrArgs)
			};
			this.#stateAttrEventHandlers[attr.name] = stateListener;

			for (const m of expr.matchAll(/\bstate\(([^)]*)\)\.([A-Za-z0-9_$]+)/g)) {
				let stateName = m[1];
				if (stateName === 'this') {
					if (thisStateName === undefined) {
						thisStateName = getStateForElement(this).getName();
					}

					stateName = thisStateName;
				} else if (stateName !== '') {
					stateName = stateName.replace(/^['"]|['"]$/g, '');
				}

				const eventName = `state(${stateName}).${m[2]}`;
				stateListener.events.push(eventName);
				document.addEventListener(eventName, stateListener.func);
			}

			if (attrCmd === 'by-window-width') {
				if (!this.#windowSizeAttrHandlers) {
					this.#windowSizeAttrHandlers = {};
				}

				this.#windowSizeAttrHandlers[attr.name] = () => func(this, handler.call(this), attrPrefix, ...attrArgs);
			}
		}

		if (this.#stateAttrEventHandlers !== null) {
			for (const name of Object.keys(this.#stateAttrEventHandlers)) {
				if (!existingAttrs.includes(name)) {
					delete this.#stateAttrEventHandlers[name];
				}
			}
		}

		if (this.#windowSizeAttrHandlers !== null) {
			for (const name of Object.keys(this.#windowSizeAttrHandlers)) {
				if (!existingAttrs.includes(name)) {
					delete this.#windowSizeAttrHandlers[name];
				}
			}
		}
	}

	async #renderIfNeeded() {
		if (!this.isConnected) {
			this.#renderRequested = false;
			return;
		}

		if (this.#renderInProgress) {
			this.#renderRequested = true;
			return;
		}

		if (this.#stateAttrEventHandlers !== null) {
			this.#updateStateAttrs();
		}

		let loopMaxIterations = 10;

		do {
			this.#renderInProgress = true;
			this.#renderRequested = false;

			try {
				const customAttrs = this.#readCustomAttrs();

				if (this.#isFirstRender) {
					this.#isFirstRender = false;
					this.attrs = customAttrs;
				} else if (!this.#renderCustomAttributes(customAttrs)) {
					// full render is not required
					return;
				}

				await this.render();
			} finally {
				this.#renderInProgress = false;
			}
		} while (this.#renderRequested && this.isConnected && --loopMaxIterations > 0);

		if (this.#renderRequested && this.isConnected) {
			if (loopMaxIterations === 0) {
				console.warn('Too many render cycles without break');
			}
			setTimeout(this.#renderIfNeeded.bind(this), 20);
		}
	}

	#renderCustomAttributes(attrs) {
		const changedAttributes = [];
		for (const [name, value] of Object.entries(attrs)) {
			if (this.attrs[name] !== value) {
				this.attrs[name] = value;
				changedAttributes.push(name);
			}
		}

		for (const name of changedAttributes) {
			const flag = this.renderAttribute(name);

			if (typeof flag !== 'boolean') {
				console.warn('renderAttribute method must return boolean');
			}

			if (!flag) {
				return true;
			}
		}

		return false;
	}

	#readCustomAttrs() {
		const out = {};

		for (const [name, defaultValue] of Object.entries(this.#defaultAttrs || {})) {
			const attrName = AbstractCustomElement.#convertCamelToKebabCase(name);
			let strValue;

			strValue = this.getAttribute(attrName);

			if (strValue === null) {
				out[name] = defaultValue;
				continue;
			}

			switch (typeof defaultValue) {
				case 'number':
					out[name] = Number(strValue) || 0;
					break;
				case 'boolean':
					out[name] = strValue === ''
						|| strValue === 'true'
						|| strValue === attrName;
					break;
				default:
					out[name] = strValue;
			}
		}

		return out;
	}

	static #convertCamelToKebabCase(text) {
		return text.replace(/([A-Z])/g, '-$1').toLowerCase();
	}
}
