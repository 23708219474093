var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<f-box color=\"default\" flex align-items=\"start\" column square class=\"changes-bar\">\n	<f-box flex padding-left=\"1\" padding-top=\"1\">\n		<f-button data-role=\"general\" small group-position=\"left\" pressed=\"true\" caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.general-updates",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":84},"end":{"line":3,"column":118}}}))
    + "\" tool-mode></f-button>\n		<f-button data-role=\"interactives\" small group-position=\"right\" bulb-color=\"warning\" caption=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.interactives",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":96},"end":{"line":4,"column":127}}}))
    + "\" tool-mode></f-button>\n	</f-box>\n	<f-box width=\"100%\" margin=\"1\" data-role=\"form\" flex>\n		<f-input-text width=\"100%\" placeholder=\""
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"title.search",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":65}}}))
    + "\" id=\"search-text\"></f-input-text>\n	</f-box>\n	<f-box data-role=\"error\" color=\"danger\" hidden></f-box>\n	<f-scroll-box flex-max padding=\"1\" padding-left=\"0\" margin-bottom=\"1\" margin-left=\"1\" margin-right=\"1\">\n		<div data-role=\"list\">\n			<div data-role=\"bottom-anchor\"></div>\n		</div>\n	</f-scroll-box>\n</f-box>\n";
},"useData":true});