import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";
import {TexturesFactory} from "ui/components/textures/TexturesFactory.js";
import {IS_CSS_CALC_SIZE_SUPPORTED} from "../../../config.js";

const EVENT_NAME_GLOBAL_HUB = 'f-details-update';

class DetailsElement extends AbstractCustomElement {
	attrs = {
		opened: false,
		group: '',
		summary: '',
		color: 'success',
	};

	/** @type {?Node[]} */
	#initialNodes;

	/** @type {HTMLElement} */
	#bulbEl;

	/** @type {HTMLElement} */
	#summaryEl;

	/** @type {HTMLElement} */
	#contEl;

	/** @type {HTMLElement} */
	#titleEl;

	#scrollTimer = 0;

	connectedCallback() {
		super.connectedCallback();
		document.addEventListener(EVENT_NAME_GLOBAL_HUB, this.#handleGlobalHubEvent);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		clearTimeout(this.#scrollTimer);
		document.removeEventListener(EVENT_NAME_GLOBAL_HUB, this.#handleGlobalHubEvent);
	}

	renderAttribute(name) {
		if (name === 'color' && this.#bulbEl) {
			this.#bulbEl.setAttribute('color', this.attrs.color);
			return true;
		}

		if (name === 'opened' && this.#bulbEl) {
			this.#bulbEl.setAttribute('on', `${this.attrs.opened}`);
			clearTimeout(this.#scrollTimer);

			if (this.attrs.opened && !this.#initialNodes) {
				this.#appendContent();
			}

			// need a little delay to start transition animation
			this.#scrollTimer = setTimeout(() => {
				this.#contEl.classList.toggle('opened', this.attrs.opened);

				if (this.attrs.opened) {
					// scroll to summary title after the transition animation
					clearTimeout(this.#scrollTimer);
					this.#scrollTimer = setTimeout(() => {
						this.#contEl.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest',
						});
					}, IS_CSS_CALC_SIZE_SUPPORTED ? 200 : 0);
				}
			}, 0);

			return true;
		}

		if (name === 'summary' && this.#titleEl) {
			this.#titleEl.textContent = this.attrs.summary;
			return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '';
		await this.#appendStyle();
		this.#appendSummary();

		this.#contEl = document.createElement('div');
		this.#contEl.className = 'cont' + (this.attrs.opened ? ' opened' : '');
		this.shadowRoot.appendChild(this.#contEl);

		if (this.attrs.opened && !this.#initialNodes) {
			this.#appendContent();
		}
	}

	/**
	 * @param {CustomEvent} e
	 */
	#handleGlobalHubEvent = (e) => {
		if (this.attrs.group === '') {
			return;
		}

		if (e.detail === this || e.detail.getAttribute('group') !== this.attrs.group) {
			return;
		}

		if (e.detail.hasAttribute('opened') && this.attrs.opened) {
			this.removeAttribute('opened');
		}
	};

	async #appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();

		style.textContent = /* css */`
			:host {
				display: block;
			}

			.summary {
				display: flex;
				align-items: center;
				gap: ${Style.getIndentPx(2)};
				padding: ${Style.getIndentPx(3)};
				cursor: pointer;
			}

			.cont {
				padding: 2px;
				background-color: ${colors.bgDark1};
				border-bottom: 1px solid ${colors.bgLight1};
				border-top: 1px solid ${colors.bgDark2};
				background-image: url("${await TexturesFactory.getNoiseTexture(colors.bg, 0, 0.6)}");
				background-position:
					${Math.ceil(Math.random() * 200)}px
					${Math.ceil(Math.random() * 200)}px;
				box-shadow: 0 0 4px ${colors.bgDark2} inset;
			}

			.body {
				@supports (height: calc-size(auto, size)) {
					transition: padding 0.2s ease-in, height 0.2s ease-in;
					overflow: hidden;
					height: 0;
					padding: 0 ${Style.getIndentPx(3)};
				}

				@supports not (height: calc-size(auto, size)) {
					padding: ${Style.getIndentPx(3)};
					display: none;
				}
			}

			.cont.opened .body {
				@supports (height: calc-size(auto, size)) {
					padding: ${Style.getIndentPx(3)};
					height: calc-size(auto, size);
				}

				@supports not (height: calc-size(auto, size)) {
					display: block;
				}
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendSummary() {
		this.#bulbEl = document.createElement('f-bulb');
		this.#bulbEl.setAttribute('color', this.attrs.color);

		if (this.attrs.opened) {
			this.#bulbEl.setAttribute('on', '');
		}

		this.#titleEl = document.createElement('div');
		this.#titleEl.textContent = this.attrs.summary;

		this.#summaryEl = document.createElement('div');
		this.#summaryEl.className = 'summary';
		this.#summaryEl.appendChild(this.#bulbEl);
		this.#summaryEl.appendChild(this.#titleEl);

		this.#summaryEl.onclick = () => {
			clearTimeout(this.#scrollTimer);
			this.setAttribute('opened', `${!this.attrs.opened}`);
			document.dispatchEvent(new CustomEvent(EVENT_NAME_GLOBAL_HUB, {detail: this}));
		};

		this.shadowRoot.appendChild(this.#summaryEl);
	}

	#appendContent() {
		if (!this.#initialNodes) {
			this.#initialNodes = [...this.childNodes];
		}

		const bodyEl = document.createElement('div');
		bodyEl.className = 'body';

		for (const el of this.#initialNodes) {
			if (el instanceof HTMLTemplateElement) {
				bodyEl.appendChild(el.content.cloneNode(true));
			} else {
				bodyEl.appendChild(el);
			}
		}

		this.#contEl.appendChild(bodyEl);
	}
}

customElements.define('f-details', DetailsElement);
