import Utils from "../../../helpers/Utils.js";

export async function createInteractiveDialog(title, url) {
	const screenEl = document.createElement('f-screen');
	screenEl.setAttribute('loading', 'true');
	screenEl.setAttribute('width', '100%');
	screenEl.setAttribute('height', '200');

	const iframeEl = document.createElement('iframe');
	iframeEl.setAttribute('frameborder', '0');
	screenEl.appendChild(iframeEl);

	const initWidth = 500;

	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('caption', title);
	dialog.setAttribute('width', `${initWidth}`);
	dialog.setAttribute('modal', '');
	dialog.setAttribute('padding', '1');
	dialog.appendChild(screenEl);

	document.body.appendChild(dialog);

	// wait until all custom elements will be appeared and for proper UI effect
	await Utils.wait(200);

	iframeEl.addEventListener('custom-load', async (e) => {
		let [width, height, hideScrollBar] = e.detail;

		if (dialog.offsetWidth < initWidth) {
			width = screenEl.offsetWidth - 10;
		}

		iframeEl.style.width = `${width}px`;
		iframeEl.style.height = `${height}px`;

		if (hideScrollBar) {
			iframeEl.setAttribute('scrolling', 'no');
		}

		screenEl.setAttribute('width', `${width + 10}`);
		screenEl.setAttribute('height', `${height + 10}`);

		dialog.setAttribute('width', `${width + 40}`);

		await Utils.wait(50);

		screenEl.setAttribute('loading', false);
		iframeEl.contentWindow.focus();
	});
	iframeEl.src = url;

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}
