import {BoxElement} from "ui/components/elements/container/BoxElement";

class ScrollBoxElement extends BoxElement {
	constructor() {
		super();
		this.attrs.color = 'face';
		this.attrs.width = '100%';
	}

	getStyle() {
		return /* css */`
			:host {
				overflow: hidden;
			}
		`;
	}

	getContentWrapper() {
		const box = document.createElement('f-box');
		box.setAttribute('y-scroll', 'auto');
		box.style.height = '100%';
		box.addEventListener('scroll', () => {
			this.style.setProperty('--scroll-y', `${box.scrollTop}px`);
		});
		return box;
	}
}
customElements.define('f-scroll-box', ScrollBoxElement);
