var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<f-box padding=\"1\" flex column justify-content=\"center\" gap=\"3\">\n	<f-screen loading:if=\"state(this).videoLoaded !== true\" aspect-ratio=\"1.327\">\n		<video style=\"width: 100%\" autoplay muted loop playsinline pip=\"false\"\n			onloadeddata=\"state(this).videoLoaded = true\"\n			>\n			<source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":29}}}) : helper)))
    + "/images/no-please.webm\" type=\"video/webm\">\n			<source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"staticUrl") || (depth0 != null ? lookupProperty(depth0,"staticUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staticUrl","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":29}}}) : helper)))
    + "/images/no-please.mp4\" type=\"video/mp4\">\n		</video>\n	</f-screen>\n	<div style=\"text-align: center\">\n		"
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"hints.unsubs",(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":11,"column":31}}}))
    + "\n	</div>\n	<f-box flex justify-content=\"center\" margin-top=\"2\" gap=\"2\">\n		<f-bulb color=\"danger\" blink:if=\"state(this).inProgress\"></f-bulb>\n		<f-button disabled:if=\"state(this).inProgress\" color=\"danger\" data-role=\"submit\" caption=\""
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.unsubscribe",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":92},"end":{"line":15,"column":122}}}))
    + "\" icon=\"close\"></f-button>\n		<f-button data-role=\"close\" caption=\""
    + alias4(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.cancel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":64}}}))
    + "\"></f-button>\n	</f-box>\n</f-box>\n";
},"useData":true});