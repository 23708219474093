import {Style} from "ui/components/styles/Style";
import {TexturesFactory} from "../../textures/TexturesFactory.js";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class AbstractInputElement extends AbstractCustomElement {
	static formAssociated = true;

	attrs = {
		placeholder: '',
		readonly: false,
		disabled: false,
		maxlength: 65536,
		stateField: '',
		width: '200px',
		icon: '',
		value: '',
	};

	/** @type {ElementInternals} */
	#internals;

	/** @type {HTMLInputElement} */
	#inputEl;

	/** @type {SvgIconElement} */
	#iconEl;

	/** @type {ButtonElement} */
	#clearBtn;

	#type = 'text';

	/**
	 * @type {(v: string) => void|null}
	 */
	#stateFieldHandler = null;

	constructor(type) {
		super(true, {delegatesFocus: true});
		this.#type = type;
		this.#internals = this.attachInternals();
	}

	get input() {
		return this.#inputEl;
	}

	get value() {
		return this.#inputEl ? this.#inputEl.value : '';
	}

	set value(v) {
		this.#internals.setFormValue(v);
		this.#inputEl.value = v;
		this.#updateState();
	}

	renderAttribute(name) {
		if (!this.#inputEl) {
			return false;
		}

		switch (name) {
			case 'disabled':
				this.#updateState();
				return true;
			case 'placeholder':
				this.#inputEl.placeholder = this.attrs.placeholder;
				return true;
			case 'maxlength':
				this.#inputEl.maxLength = this.attrs.maxlength;
				return true;
			case 'readonly':
				this.#inputEl.readOnly = this.attrs.readonly;
				return true;
			case 'value':
				this.#internals.setFormValue(this.attrs.value);
				this.#inputEl.value = this.attrs.value;
				this.#updateState();
				return true;
			case 'stateField':
				this.#readStateField();
				return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '';
		await this.#appendStyle();
		this.#appendIcon();
		this.#appendInput();

		if (!this.attrs.readonly) {
			this.#appendClearButton();
		}

		this.#readStateField();
		this.#updateState();
		this.#internals.setFormValue(this.attrs.checked ? this.attrs.value : '');
	}

	async #appendStyle() {
		const colors = Style.getColorsSet();
		const dangerColors = Style.getColorsSet('danger');
		const height = Style.getBaseHeightPx(3);
		const borderRadius = Style.getBorderRadiusPx(1);

		const style = document.createElement('style');
		const width = /^\d+$/.test(this.attrs.width) ? `${this.attrs.width}px` : this.attrs.width;
		const scale = window.devicePixelRatio > 1 ? 2 : 1;

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				align-items: center;
				height: ${height};
				box-sizing: border-box;
				width: ${width};
				gap: ${Style.getIndentPx(1)};
				vertical-align: middle;
			}

			.cont {
				flex: 1px;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});
				height: ${height};
				position: relative;
				border-radius: ${borderRadius};

				&::before {
					content: '';
					position: absolute;
					inset: 1px 1px 1px 1px;
					border-radius: ${borderRadius};
					background-color: ${colors.bgDark2};
					z-index: 0;
				}

				&::after {
					content: '';
					position: absolute;
					inset: 3px 3px 3px 3px;
					border-radius: ${borderRadius};
					background-color: ${colors.bgDark1};
					background-image:
						url("${await TexturesFactory.getScratches()}"),
						url("${await TexturesFactory.getLedMatrixBg(scale)}");
					background-size: auto, 3px 3px;
					background-position: ${Math.ceil(Math.random() * 200)}px ${Math.ceil(Math.random() * 200)}px, 0 0;
					z-index: 1;
					box-shadow:
						0 1px ${colors.bg} inset,
						1px 0 ${colors.bgDark1} inset,
						-1px 0 ${colors.bgDark1} inset,
						0 -1px ${colors.bgDark2} inset;
				}

				&:focus, &:has(input:focus) {
					&::before {
						background-color: color-mix(in srgb, ${colors.bgDark2}, ${colors.glow} 50%);
					}

					&::after {
						background-color: color-mix(in srgb, ${colors.bgDark1}, ${colors.glow} 7%);
						animation-name: noise-animation;
						animation-duration: 2s;
						animation-fill-mode: forwards;
						animation-iteration-count: infinite;
						animation-timing-function: steps(5, jump-start);
						background-image:
							url("${await TexturesFactory.getNoiseTexture(colors.glow, 0.01, 0.1)}"),
							url("${await TexturesFactory.getScratches()}"),
							url("${await TexturesFactory.getLedMatrixBg(scale)}");
						background-size: auto, auto, 3px 3px;
						box-shadow:
							0 0 6px ${colors.glow},
							0 0 2px ${colors.glow} inset;
					}

					input::placeholder {
						color: ${colors.glow};
						text-shadow: 0 0 4px ${colors.glow};
						opacity: 0.4;
					}
				}
			}

			@keyframes noise-animation {
				0% {background-position: 0 0, 0 0, 0 0;}
				20% {background-position: 200px 200px, 0 0, 0 0;}
				40% {background-position: 0px 150px, 0 0, 0 0;}
				60% {background-position: 40px 60px, 0 0, 0 0;}
				80% {background-position: 80px 10px, 0 0, 0 0;}
				100% {background-position: 50px 50px, 0 0, 0 0;}
			}

			f-icon {
				color: ${colors.textDark1};
				z-index: 2;
			}

			input {
				flex: 1;
				width: 100%;
				height: 100%;
				position: relative;
				box-sizing: border-box;
				background-color: transparent;
				border: none;
				outline: none;
				box-shadow: none;
				appearance: none;
				padding-left: ${Style.getIndentPx(2)};
				color: ${colors.text};
				text-shadow: 0 0 4px ${colors.text};
				z-index: 2;

				&[disabled] {
					cursor: not-allowed;
				}
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type=number] {
				-moz-appearance: textfield;
			}

			input::selection {
				color: ${colors.glow};
				box-shadow: 0 0 4px ${colors.glow};
				background-color: ${colors.bg};
			}

			input::placeholder {
				color: ${colors.textDark2};
				text-shadow: 0 0 4px ${colors.bgLight1};
				opacity: 0.5;
			}

			.cont:has(input:invalid) {
				&::before {
					background-color: color-mix(in srgb, ${colors.bgDark2}, ${dangerColors.bgDark2} 50%);
				}

				&::after {
					box-shadow:
						0 0 6px ${dangerColors.bg},
						0 0 2px ${dangerColors.bg} inset;
				}

				input {
					color: ${dangerColors.bgLight1};
					text-shadow: 0 0 4px ${dangerColors.bg};
				}
			}

			.cont:focus:has(input:invalid), .cont:has(input:invalid:focus) {
				&::after {
					background-image:
						url("${await TexturesFactory.getNoiseTexture(dangerColors.bgDark2, 0.0, 0.6)}"),
						url("${await TexturesFactory.getScratches()}"),
						url("${await TexturesFactory.getLedMatrixBg(scale)}");
					background-size: auto, auto, 3px 3px;
				}
			}

			:host([disabled]:not([disabled="false"])) {
				cursor: not-allowed;
				pointer-events: none;
			}

			:host([disabled]:not([disabled="false"])) f-icon {
				opacity: 0.5;
			}

			:host([disabled]:not([disabled="false"])) input::placeholder {
				color: ${dangerColors.bgLight1};
				text-shadow: 0 0 4px ${dangerColors.bg};
			}

			:host([disabled]:not([disabled="false"])) .cont {
				&::before {
					background-color: color-mix(in srgb, ${colors.bgDark2}, ${dangerColors.bgDark2} 50%);
				}

				&::after {
					box-shadow:
						0 0 6px ${dangerColors.bg},
						0 0 2px ${dangerColors.bg} inset;
				}
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendIcon() {
		if (!this.attrs.icon) {
			this.#iconEl = undefined;
			return;
		}

		this.#iconEl = document.createElement('f-icon');
		this.#iconEl.setAttribute('icon', this.attrs.icon);
		this.#iconEl.setAttribute('size', '2');

		this.shadowRoot.appendChild(this.#iconEl);
	}

	#appendInput() {
		const cont = document.createElement('div');
		cont.className = 'cont';

		this.#inputEl = document.createElement('input');
		this.#inputEl.type = this.#type;
		this.#inputEl.maxLength = this.attrs.maxlength;
		this.#inputEl.placeholder = this.attrs.placeholder;
		this.#inputEl.readOnly = this.attrs.readonly;
		this.#inputEl.disabled = this.hasAttribute('disabled');
		this.#inputEl.value = this.attrs.value;

		const handler = (e) => {
			setTimeout(this.#updateState.bind(this), 0);
			this.dispatchEvent(new CustomEvent(e.type));
		};
		this.#inputEl.onkeydown = handler;
		this.#inputEl.onkeyup = handler;
		this.#inputEl.oninput = handler;
		this.#inputEl.onchange = handler;
		this.#inputEl.onpaste = handler;
		this.#inputEl.onfocus = handler;

		cont.appendChild(this.#inputEl);
		this.shadowRoot.appendChild(cont);
	}

	#appendClearButton() {
		this.#clearBtn = document.createElement('f-button');
		this.#clearBtn.setAttribute('icon', 'close');
		this.#clearBtn.setAttribute('small', 'true');

		if (this.hasAttribute('disabled')) {
			this.#clearBtn.setAttribute('disabled', '');
		}

		this.#clearBtn.onclick = () => {
			this.value = '';
			this.dispatchEvent(new CustomEvent('change'));
		}

		this.shadowRoot.appendChild(this.#clearBtn);
	}

	#readStateField() {
		if (!this.attrs.stateField) {
			this.#stateFieldHandler = null;
			return;
		}

		this.#stateFieldHandler = new Function('__val', this.attrs.stateField + ' = __val;');
	}

	#updateState() {
		if (this.#clearBtn) {
			this.#clearBtn.setAttribute('disabled', this.attrs.disabled ? 'true' : 'false');
		}

		if (this.#stateFieldHandler) {
			this.#stateFieldHandler.call(this, this.#inputEl.value);
		}
	}
}

class InputTextElement extends AbstractInputElement {
	constructor() {
		super('text');
	}
}

class InputNumberElement extends AbstractInputElement {
	constructor() {
		super('number');
		this.attrs.min = -Number.MAX_VALUE;
		this.attrs.max = Number.MAX_VALUE;
		this.attrs.step = 1;
	}

	renderAttribute(name) {
		const inputEl = this.input;
		if (!inputEl) {
			return;
		}

		switch (name) {
			case 'min':
				inputEl.min = `${this.attrs.min}`;
				break;
			case 'max':
				inputEl.max = `${this.attrs.max}`;
				break;
			case 'step':
				inputEl.step = `${this.attrs.step}`;
				break;
			default:
				return super.renderAttribute(name);
		}

		return false;
	}

	async render() {
		await super.render();

		if (this.attrs.min !== -Number.MAX_VALUE) {
			this.input.min = this.attrs.min;
		}

		if (this.attrs.max !== Number.MAX_VALUE) {
			this.input.max = this.attrs.max;
		}

		if (this.attrs.step !== 0) {
			this.input.step = this.attrs.step;
		}
	}
}

class InputPasswordElement extends AbstractInputElement {
	constructor() {
		super('password');
	}
}

customElements.define('f-input-text', InputTextElement);
customElements.define('f-input-number', InputNumberElement);
customElements.define('f-input-password', InputPasswordElement);
