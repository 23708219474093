import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

const EVENT_NAME_GLOBAL_HUB = 'f-radio-update';

class RadioElement extends AbstractCustomElement {
	static formAssociated = true;

	attrs = {
		caption: '',
		value: '',
		name: '',
		checked: false,
		stateField: '',
	};

	/** @type {ElementInternals} */
	#internals;

	/** @type {?HTMLElement} */
	#radioEl;

	/** @type {?HTMLElement} */
	#captionEl;

	/**
	 * @type {(v: string) => void|null}
	 */
	#stateFieldHandler = null;

	constructor() {
		super(true, {delegatesFocus: true});
		this.#internals = this.attachInternals();
		this.addEventListener('click', this.#toggleFlag.bind(this));
	}

	connectedCallback() {
		super.connectedCallback();
		document.addEventListener(EVENT_NAME_GLOBAL_HUB, this.#handleGlobalHubEvent);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		document.removeEventListener(EVENT_NAME_GLOBAL_HUB, this.#handleGlobalHubEvent);
	}

	renderAttribute(name) {
		if (name === 'checked' && this.#radioEl) {
			this.#updateState();
			return true;
		}


		if (name === 'stateField') {
			this.#readStateField();
			return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '';
		this.#readStateField();
		this.#appendStyle();
		this.#appendButton();
		this.#appendCaption();
		this.#updateState();
	}

	/**
	 * @param {CustomEvent} e
	 */
	#handleGlobalHubEvent = (e) => {
		if (e.detail === this || e.detail.getAttribute('name') !== this.attrs.name) {
			return;
		}

		if (e.detail.hasAttribute('checked') && this.attrs.checked) {
			this.removeAttribute('checked');
		}
	};

	#updateState() {
		if (this.attrs.checked) {
			this.#internals.setFormValue(this.attrs.value);
			this.#stateFieldHandler && this.#stateFieldHandler.call(this, this.attrs.value);
		}
	}

	#appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();
		const successColors = Style.getColorsSet('success');
		const dangerColors = Style.getColorsSet('danger');
		const radioSize = Style.getBaseHeightPx(1);

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				align-items: center;
				color: var(${colors.text});
				cursor: pointer;
				gap: ${Style.getIndentPx(1)};
				vertical-align: middle;
			}

			.radio {
				display: flex;
				align-items: center;
				justify-content: center;
				width: ${radioSize};
				height: ${radioSize};
				border-radius: 50%;
				box-sizing: border-box;
				position: relative;
				outline: none;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});

				&::before {
					content: '';
					position: absolute;
					inset: 1px 1px 1px 1px;
					border-radius: 50%;
					background-color: ${colors.bgDark2};
					z-index: 1;
				}

				& .icon {
					position: absolute;
					top: 3px;
					left: 3px;
					width: calc(${radioSize} - 6px);
					height: calc(${radioSize} - 6px);
					border-radius: 50%;
					background-image: linear-gradient(${colors.bgDark1}, ${colors.bg});
					z-index: 2;

					&::before {
						content: '';
						position: absolute;
						inset: 1px;
						border-radius: 50%;
						background-image: linear-gradient(${colors.bgLight2}, transparent 40%);
					}
				}
			}

			.caption {
				user-select: none;
			}

			:host(:focus:not([disabled])) .radio::before {
				box-shadow: 0 0 4px 1px color-mix(in srgb, ${colors.bgDark2}, ${colors.glow} 50%) inset;
				background-color: color-mix(in srgb, ${colors.bgDark2}, ${colors.glow} 3%);
			}

			:host(:focus:not([disabled])) .icon {
				background-image: linear-gradient(
					color-mix(in srgb, ${colors.bgDark1}, ${colors.glow} 5%),
					color-mix(in srgb, ${colors.bg}, ${colors.glow} 5%)
				);

				&::before {
					background-image: linear-gradient(
						color-mix(in srgb, ${colors.bgLight1}, ${colors.glow} 50%),
						transparent 40%
					);
				}
			}

			:host([checked]:not([disabled])) .radio {
				background-image: linear-gradient(${colors.bgDark1}, ${successColors.bg});

				&::before {
					background-color: color-mix(in srgb, ${colors.bgDark2}, ${successColors.bgLight1} 30%);
					box-shadow: 0 0 6px ${successColors.glow};
				}
			}


			:host([checked]:not([disabled])) .icon {
				box-shadow: 0 0 3px ${successColors.glow};
				background-image: radial-gradient(${successColors.bgLight2}, ${successColors.bg});

				&::before {
					background-image: linear-gradient(#fff, transparent 40%);
				}
			}

			:host([disabled]) {
				cursor: not-allowed;
			}

			:host([disabled]) .caption {
				opacity: 0.5;
			}

			:host([disabled]) .radio::before {
				box-shadow: 0 0 4px 1px color-mix(in srgb, ${colors.bgDark2}, ${dangerColors.bg} 50%) inset;
				background-color: color-mix(in srgb, ${colors.bgDark2}, ${dangerColors.bg} 3%);
			}

			:host([disabled]) .icon {
				background-image: linear-gradient(
					color-mix(in srgb, ${colors.bgDark2}, ${dangerColors.bgDark2} 20%),
					color-mix(in srgb, ${colors.bgDark1}, ${dangerColors.bgDark1} 20%)
				);
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendButton() {
		this.#radioEl = document.createElement('div');
		this.#radioEl.tabIndex = 0;
		this.#radioEl.className = 'radio';

		this.#radioEl.onkeydown = (e) => {
			if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Space') {
				this.#toggleFlag();
			}
		};

		const dotEl = document.createElement('div');
		dotEl.className = 'icon';

		this.#radioEl.appendChild(dotEl);
		this.shadowRoot.appendChild(this.#radioEl);
	}

	#appendCaption() {
		this.#captionEl = document.createElement('div');
		this.#captionEl.className = 'caption';
		this.#captionEl.textContent = this.attrs.caption;
		this.shadowRoot.appendChild(this.#captionEl);
	}

	#toggleFlag() {
		if (this.hasAttribute('disabled')) {
			return;
		}

		if (!this.attrs.checked) {
			this.setAttribute('checked', '');
		} else {
			this.removeAttribute('checked');
		}
		document.dispatchEvent(new CustomEvent(EVENT_NAME_GLOBAL_HUB, {detail: this}));
	}

	#readStateField() {
		if (!this.attrs.stateField) {
			this.#stateFieldHandler = null;
			return;
		}

		this.#stateFieldHandler = new Function('__val', this.attrs.stateField + ' = __val;');
	}
}

customElements.define('f-radio', RadioElement);
