import {Style} from "ui/components/styles/Style";
import {AbstractTexture} from "./AbstractTexture.js";
/**
 * @typedef {object} RidgeBorderTextureOptions
 * @property {string} [darkColor]
 * @property {string} [lightColor]
 * @property {string} [fillColor]
 */

export class RidgeBorderTexture extends AbstractTexture {
	/** @type {RidgeBorderTextureOptions} */
	#options = {
		darkColor: '',
		lightColor: '',
		fillColor: '',
	};

	constructor() {
		super();

		const colorsSet = Style.getColorsSet();
		this.#options.darkColor = colorsSet.bgDark2.cssValue;
		this.#options.lightColor = colorsSet.bgLight1.cssValue;
	}

	/**
	 * @param {RidgeBorderTextureOptions} options
	 * @returns {RidgeBorderTexture}
	 */
	setOptions(options) {
		Object.assign(this.#options, options);
		return this;
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 */
	renderTexture(ctx, width) {
		ctx.save();

		const radius = width / 3 / 1.1;

		ctx.strokeStyle = this.#options.lightColor;
		ctx.beginPath();
		ctx.roundRect(1 - 0.5, 2 - 0.5, width - 2, width - 2, radius);
		ctx.stroke();

		if (this.#options.fillColor) {
			ctx.fillStyle = this.#options.fillColor;
			ctx.beginPath();
			ctx.roundRect(2 - 0.5, 2 - 0.5, width - 3, width - 3, radius);
			ctx.fill();
		}

		ctx.strokeStyle = this.#options.darkColor;
		ctx.beginPath();
		ctx.roundRect(2 - 0.5, 1 - 0.5, width - 2, width - 2, radius);
		ctx.stroke();

		ctx.restore();
	}
}
