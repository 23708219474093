import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class ValueElement extends AbstractCustomElement {
	attrs = {
		value: '',
	};

	/** @type {HTMLSpanElement} */
	#valueEl;

	renderAttribute(name) {
		if (name === 'value' && this.#valueEl) {
			this.#valueEl.textContent = this.attrs.value;
			return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '<div class="bg"></div>';
		this.#appendStyle();

		if (!this.#valueEl) {
			this.#valueEl = document.createElement('span');
		}

		this.#valueEl.textContent = this.attrs.value;
		this.shadowRoot.appendChild(this.#valueEl);
	}

	#appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();
		const indent = Style.getIndentPx(3);
		const height = Style.getBaseHeightDec(2);
		const radius = height / 2;

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				align-items: center;
				min-width: 30px;
				height: ${height}px;
				border-radius: ${radius}px;
				box-sizing: border-box;
				position: relative;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});
			}

			:host::before {
				content: '';
				position: absolute;
				inset: 1px 1px 1px 1px;
				border-radius: ${radius - 1}px;
				background-color: ${colors.bgDark2};
				z-index: 1;
			}

			.bg {
				position: absolute;
				top: 2px;
				left: 2px;
				right: 2px;
				bottom: 2px;
				border-radius: ${radius - 2}px;
				background-image: linear-gradient(${colors.bgDark1} 60%, ${colors.bg});
				z-index: 2;
				overflow: hidden;

				&::before {
					content: '';
					position: absolute;
					inset: 0;
					border-radius: ${radius - 2}px;
					background-image: linear-gradient(${colors.bgLight2}, transparent 40%);
					z-index: 1;
				}
			}

			span {
				position: relative;
				z-index: 3;
				padding: 0 ${indent};
				text-shadow: 0 0 1px ${colors.glow};
			}
		`;
		this.shadowRoot.appendChild(style);
	}
}

customElements.define('f-value', ValueElement);
