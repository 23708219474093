import {ScratchesTexture} from "./ScratchesTexture.js";
import {RidgeBorderTexture} from "./RidgeBorderTexture.js";
import {Style} from "ui/components/styles/Style";
import {WarningBorderTexture} from "./WarningBorderTexture.js";
import {LedMatrixTexture} from "./LedMatrixTexture.js";
import {NoiseTexture} from "./NoiseTexture.js";
import {LinesTexture} from "ui/components/textures/LinesTexture";

const TEXTURE_RANDOM_SEED = 796;

/** @type {Map<string,string>} name => url */
const loadedTextures = new Map();
const loadList = new Map();

export class TexturesFactory {
	static async getVerticalLines(step = 2, linesColor = '') {
		return TexturesFactory.#getTexture(
			`v_lines:${step}${linesColor}`,
			() => new LinesTexture().setOptions({step, linesColor}).render(10, step)
		);
	}

	static async getHorizontalLines(step = 2, linesColor = '') {
		return TexturesFactory.#getTexture(
			`h_lines:${step}${linesColor}`,
			() => new LinesTexture().setOptions({step, linesColor, horizontal: true}).render(step, 10)
		);
	}

	static async getScratches(noise = true) {
		return TexturesFactory.#getTexture(
			`scratches_noise:${noise}`,
			() => new ScratchesTexture(TEXTURE_RANDOM_SEED).setOptions({noise}).render(800, 600)
		);
	}

	static async getRidgeBorder(width, fillColor = '') {
		return TexturesFactory.#getTexture(
			`ridge_border_${width}_fill:${fillColor}`,
			() => new RidgeBorderTexture()
				.setOptions({fillColor: fillColor ? Style.getColorsSet(fillColor).bg.cssValue : ''})
				.render(width * 3, width * 3)
		);
	}

	static async getWarningBorder(width, scale, fillColor = '', linesColor = '#fff', bgColorName = 'warning') {
		let bgColor = '';
		if (bgColorName !== '') {
			bgColor = Style.getColorsSet(bgColorName).bg.cssValue;
		}

		return TexturesFactory.#getTexture(
			`warning_border_${width}_${scale}_fill:${fillColor}_lines:${linesColor}`,
			() => new WarningBorderTexture()
				.setOptions({
					bgColor,
					linesColor,
					fillColor: fillColor ? Style.getColorsSet(fillColor).bg.cssValue : ''
				})
				.render(width * 3 * scale, width * 3 * scale)
		);
	}

	static async getLedMatrixBg(scale, linesColor = '') {
		if (linesColor === '') {
			linesColor = Style.getColorsSet().bgDark2.cssValue;
		}

		return TexturesFactory.#getTexture(
			`led_matrix_lines:${linesColor}_${scale}`,
			() => new LedMatrixTexture()
				.setOptions({linesColor, scale})
				.render(3 * scale, 3 * scale)
		);
	}

	static async getNoiseTexture(color = '', minAlpha = 0.1, maxAlpha = 0.4) {
		if (color === '') {
			color = Style.getColorsSet().bgLight1.cssValue;
		}

		return TexturesFactory.#getTexture(
			`noise:${color},${minAlpha},${maxAlpha}`,
			() => new NoiseTexture()
				.setOptions({color, minAlpha, maxAlpha})
				.render(500, 200)
		);
	}

	/**
	 * @param {string} name
	 * @param {() => Promise<string>} renderer
	 * @returns {Promise<string>}
	 */
	static async #getTexture(name, renderer) {
		let url = loadedTextures.get(name);
		if (url) {
			return url;
		}

		let promise = loadList.get(name);
		if (promise) {
			return await promise;
		}

		promise = renderer();
		loadList.set(name, promise);

		url = await promise;

		loadedTextures.set(name, url);
		loadList.delete(name);

		return url;
	}
}
