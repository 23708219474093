import Ajax from "ui/helpers/Ajax";
import {i18n} from "ui/i18n";
import {validateEmail} from "ui/helpers/validators";
import {createErrorAlert, createSuccessAlert} from "../../dialogs/alert/alert-dialog.js";
const PATH_REGEXP = /#unsubscribe\/([^\/]+)\/([^\/]+)$/;
const CODE_LENGTH = 32;

export default class ConfirmUnsubscribe {
	/**
	 * @param {string} path
	 * @return {boolean}
	 */
	static isConfirmPath(path) {
		const matches = path.match(PATH_REGEXP);
		if (!matches) {
			return false;
		}

		const token = decodeURIComponent(matches[2]);

		return validateEmail(decodeURIComponent(matches[1])) === ''
			&& token.length === CODE_LENGTH
			&& /^[A-Z0-9]+$/i.test(token);
	}

	/**
	 * @return {Promise<boolean>}
	 */
	async process(path) {
		const {email, code} = this._parsePath(path);

		let res;
		try {
			res = await Ajax.post('/subs/unsubscribe/' + encodeURIComponent(email), {code});

			if (res.result !== 'ok') {
				await createErrorAlert(res.error);
				return false;
			}
		} catch (e) {
			await createErrorAlert(typeof e === 'string' ? e : i18n('errors.http.error'));
			return false;
		}

		await createSuccessAlert(i18n('messages.confirm-unsub-success', email));
	}

	/**
	 * @param {string} path
	 * @return {{code: string, email: string}}
	 * @private
	 */
	_parsePath(path) {
		const matches = path.match(PATH_REGEXP);
		return {
			email: decodeURIComponent(matches[1]),
			code: decodeURIComponent(matches[2]),
		};
	}
}
