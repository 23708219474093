export class AbstractTexture {
	/** @type {string} */
	#url = '';

	/** @type {number} */
	#width = 0;

	/** @type {number} */
	#height = 0;

	/** @type {number} */
	#seed = 0;

	/** @type {number} */
	#randomazerIncrement = 0;

	constructor(seed = 0) {
		this.#seed = seed;
	}

	/**
	 * @abstract
	 * @protected
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 * @param {number} height
	 */
	renderTexture(ctx, width, height) {
		// for overriding
	}

	/**
	 * @protected
	 * @param {number} from
	 * @param {number} to
	 * @returns {number}
	 */
	getPseudoRandomNumber(from, to) {
		this.#randomazerIncrement++;
		this.#seed = (142 * this.#seed + 231 + this.#randomazerIncrement) % 0xFFFFFFFFFF;
		return from + (this.#seed % (to - from + 1));
	}

	/**
	 * @param {number} width
	 * @param {number} height
	 * @return {Promise<string>} Image object URI
	 */
	async render(width, height) {
		if (this.#url !== '' && this.#width === width && this.#height === height) {
			return this.#url;
		}

		if (this.#url !== '') {
			URL.revokeObjectURL(this.#url);
		}

		this.#width = width;
		this.#height = height;

		const canvas = document.createElement('canvas');
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext('2d');

		this.renderTexture(ctx, width, height);

		return await new Promise(resolve => canvas.toBlob((blob) => {
			const url = URL.createObjectURL(blob);
			resolve(url);
		}));
	}
}
