import {i18n} from "ui/i18n";
import tpl from "./unsubscribe-dialog.handlebars";
import Template from "ui/Template";
import {getState} from "../../../components/elements/state.js";
import {createErrorAlert, createSuccessAlert} from "../../../dialogs/alert/alert-dialog.js";
import Ajax from "../../../helpers/Ajax.js";
import Utils from "../../../helpers/Utils.js";

/**
 * @param {string} email
 * @param {string} token
 * @return {Promise<void>}
 */
export async function createUnsubscribeDialog(email, token) {
	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('caption', i18n('title.unsubscribe'));
	dialog.setAttribute('width', '440');
	dialog.setAttribute('modal', '');
	dialog.setAttribute('init-state', 'unsubscribe-dialog');
	dialog.innerHTML = new Template(tpl).createHtml({email});

	dialog.addEventListener('f-button-click', (e) => {
		if (e.detail.dataset.role === 'close') {
			dialog.remove();
			return;
		}

		if (e.detail.dataset.role === 'submit') {
			submitForm(email, token).then(async res => {
				if (res) {
					await createSuccessAlert(i18n('messages.confirm-unsub-success', email));
					dialog.remove();
				}
			});
			return;
		}
	});

	document.body.appendChild(dialog);

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}

async function submitForm(email, token) {
	const state = getState('unsubscribe-dialog');
	state.inProgress = true;

	// sleep for proper animation effect
	await Utils.wait(500);

	try {
		const res = await Ajax.post(
			`/subs/unsubscribe-by-token/${encodeURIComponent(token)}/${encodeURIComponent(email)}`,
			{}
		);

		if (res.result !== 'ok') {
			state.inProgress = false;
			await createErrorAlert(res.error);
			return false;
		}
	} catch (e) {
		state.inProgress = false;
		console.error(e);
		await createErrorAlert(typeof e === 'string' ? e : i18n('errors.http.error'));
		return false;
	}

	state.inProgress = false;

	return true;
};
