import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class CheckboxElement extends AbstractCustomElement {
	static formAssociated = true;

	attrs = {
		caption: '',
		value: 'on',
		fontSize: '',
		checked: false,
		stateField: '',
	};

	/** @type {ElementInternals} */
	#internals;

	/** @type {?HTMLElement} */
	#checkboxEl;

	/** @type {?HTMLElement} */
	#button;

	/** @type {?HTMLElement} */
	#captionEl;

	/**
	 * @type {(v: boolean) => void|null}
	 */
	#stateFieldHandler = null;

	constructor() {
		super(true, {delegatesFocus: true});
		this.#internals = this.attachInternals();
		this.addEventListener('click', this.#toggleFlag.bind(this));
	}

	renderAttribute(name) {
		if (name === 'checked' && this.#checkboxEl) {
			this.#internals.setFormValue(this.attrs.checked ? this.attrs.value : '');
			this.#stateFieldHandler && this.#stateFieldHandler.call(this, this.attrs.checked);
			return true;
		}

		if (name === 'stateField') {
			this.#readStateField();
			return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '';
		this.#readStateField();
		this.#appendStyle();
		this.#appendCheckbox();
		this.#appendCaption();
		this.#internals.setFormValue(this.attrs.checked ? this.attrs.value : '');
	}

	#appendStyle() {
		const colors = Style.getColorsSet();
		const successColors = Style.getColorsSet('success');
		const dangerColors = Style.getColorsSet('danger');
		const style = document.createElement('style');
		const checkBoxSize = Style.getBaseHeightPx(1);

		style.textContent = /* css */`
			:host {
				display: inline-flex;
				align-items: center;
				color: ${colors.text};
				cursor: pointer;
				gap: ${Style.getIndentPx(1)};
				vertical-align: middle;
				${this.attrs.fontSize !== '' ? `font-size: ${Style.getFontSizePx(this.attrs.fontSize)};` : ''}
			}

			.checkbox {
				width: calc(${checkBoxSize} * 2);
				height: ${checkBoxSize};
				border-radius: calc(${checkBoxSize} / 2);
				padding: 2px;
				box-sizing: border-box;
				position: relative;
				outline: none;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});

				&::before {
					content: '';
					position: absolute;
					inset: 1px 1px 1px 1px;
					border-radius: calc(${checkBoxSize} / 2);
					background-color: ${colors.bgDark2};
				}
			}

			.button {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 1px;
				width: calc(${checkBoxSize});
				height: calc(${checkBoxSize} - 4px);
				border-radius: calc((${checkBoxSize} - 4px) / 2);
				box-sizing: border-box;
				border: 1px solid ${colors.bgDark1};
				background-color: ${colors.bg};
				position: relative;
				transition: transform 0.1s ease-in;
				box-shadow:
					0 1px 1px ${colors.bgLight2} inset,
					0 -1px 1px ${colors.bgDark1} inset;

				& span {
					width: 0;
					height: 30%;
					border-left: 1px solid ${colors.bgDark1};
					border-right: 1px solid ${colors.bgLight1};
				}
			}

			.caption {
				user-select: none;
				text-shadow: -1px 1px 0 ${colors.textShadow};
			}

			:host(:focus:not([disabled]))  .checkbox::before {
				box-shadow: 0 0 4px 1px color-mix(in srgb, ${colors.bgDark2}, ${colors.glow} calc(70% * var(--glow-factor))) inset;
				background-color: color-mix(in srgb, ${colors.bgDark2}, ${colors.glow} calc(5% * var(--glow-factor)));
			}

			:host(:focus:not([disabled])) .button {
				border: 1px solid color-mix(in srgb, ${colors.bgDark1}, ${colors.glow} calc(40% * var(--glow-factor)));
			}

			:host([checked]) {
				& .button {
					transform: translateX(calc(${checkBoxSize} - 4px));
				}
			}

			:host([checked]:not([disabled])) .checkbox {
				background-image: linear-gradient(${colors.bgDark1}, ${successColors.bg});
			}

			:host([checked]:not([disabled])) .checkbox::before {
				background-color: color-mix(in srgb, ${colors.bgDark2}, ${successColors.bgLight1} 30%);
				box-shadow: 0 0 calc(4px * var(--glow-factor)) ${successColors.glow} inset;
			}

			:host([checked]:not([disabled])) .button {
				border: 1px solid color-mix(in srgb, ${colors.bgDark1}, ${successColors.glow} 50%);
				box-shadow: 0 0 calc(2px * var(--glow-factor)) ${successColors.glow} inset;
				background-color: color-mix(in srgb, ${colors.bg}, ${successColors.bg} 20%);
			}

			:host([checked]:not([disabled])) button span {
				border-left: 1px solid color-mix(in srgb, ${colors.bgDark1}, ${successColors.glow} 10%);
				border-right: 1px solid color-mix(in srgb, ${colors.bg}, ${successColors.glow} 30%);
			}

			:host(:focus) {
				--glow-factor: 1;
			}

			:host([checked]) {
				--glow-factor: 1;
			}

			:host([checked]:focus) {
				--glow-factor: 1.5;
			}

			:host([disabled]) {
				cursor: not-allowed;
			}

			:host([disabled]) .caption {
				opacity: 0.5;
			}

			:host([disabled]) .checkbox {
				background-image: linear-gradient(${colors.bgDark1}, ${dangerColors.bg});
			}

			:host([disabled]) .checkbox::before {
				background-color: color-mix(in srgb, ${colors.bgDark2}, ${dangerColors.bgDark1} 10%);
				box-shadow: 0 0 4px ${dangerColors.bg} inset;
			}

			:host([disabled]) .button {
				border: 1px solid color-mix(in srgb, ${colors.bgDark1}, ${dangerColors.bgDark1} 20%);
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendCheckbox() {
		this.#checkboxEl = document.createElement('div');
		this.#checkboxEl.className = 'checkbox';
		this.#checkboxEl.tabIndex = 0;
		this.#checkboxEl.onkeydown = (e) => {
			if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Space') {
				this.#toggleFlag();
			}
		};

		this.#button = document.createElement('div');
		this.#button.className = 'button';
		this.#button.innerHTML = '<span></span><span></span><span></span>';

		this.#checkboxEl.appendChild(this.#button);
		this.shadowRoot.appendChild(this.#checkboxEl);
	}

	#appendCaption() {
		this.#captionEl = document.createElement('div');
		this.#captionEl.className = 'caption';
		this.#captionEl.textContent = this.attrs.caption;
		this.shadowRoot.appendChild(this.#captionEl);
	}

	#toggleFlag() {
		if (this.hasAttribute('disabled')) {
			return;
		}

		if (!this.attrs.checked) {
			this.setAttribute('checked', '');
		} else {
			this.removeAttribute('checked');
		}
	}

	#readStateField() {
		if (!this.attrs.stateField) {
			this.#stateFieldHandler = null;
			return;
		}

		this.#stateFieldHandler = new Function('__val', this.attrs.stateField + ' = __val;');
	}
}

customElements.define('f-checkbox', CheckboxElement);
