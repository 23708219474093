import {AbstractCustomElement} from "../AbstractCustomElement.js";
import iconsSource from "./icons.svg"
import {Style} from "ui/components/styles/Style";

const MIN_SIZE_PX = 10;
const SIZE_STEP_PX = 2;

class SvgIconElement extends AbstractCustomElement {
	attrs = {
		icon: 'warning',
		size: 2,
		loading: false,
	};

	/**
	 * @type {Map<string,{viewBox: string, content: string}>|null}
	 */
	static #icons = null;

	async render() {
		if (this.attrs.icon === '') {
			this.shadowRoot.innerHTML = '';
			return;
		}

		if (!SvgIconElement.#icons) {
			this.#buildIconsElemFromString(decodeURIComponent(iconsSource));
		}

		const iconName = this.attrs.loading ? 'spinner' : this.attrs.icon;

		const svg = SvgIconElement.#icons.get(iconName);
		if (!svg) {
			this.shadowRoot.innerHTML = '';
			return;
		}

		const sizePx = `${this.#getSizePx(this.attrs.size)}`;
		const colors = Style.getColorsSet();

		this.shadowRoot.innerHTML = /* css */`
			<style>
				:host {
					display: inline-flex;
				}

				:host([hidden]) {
					display: none;
				}

				svg {
					filter: drop-shadow(-1px 1px 0 var(--shadow-color, ${colors.textShadow}));
				}

				${this.#getSpinAnimationCss()}
			</style>
			<svg xmlns="http://www.w3.org/2000/svg" width="${sizePx}" height="${sizePx}" viewBox="${svg.viewBox}">
				${svg.content}
			</svg>
		`;
	}

	renderAttribute(name) {
		if (name === 'size' && this.shadowRoot.firstChild) {
			const sizePx = `${this.#getSizePx(this.attrs.size)}`;

			/** @type {SVGElement} */
			const svg = this.shadowRoot.querySelector('svg');
			svg.setAttribute('width', sizePx);
			svg.setAttribute('height', sizePx);
			return true;
		}

		return false;
	}

	#getSpinAnimationCss() {
		if (!this.attrs.loading) {
			return '';
		}

		return /* css */`
			svg {
				animation: rotate 2s linear infinite;

				& circle {
					stroke: hsl(210, 70, 75);
					stroke-linecap: round;
					animation: dash 1.5s ease-in-out infinite;
				}

			}

			@keyframes rotate {
				100% {
					transform: rotate(360deg);
				}
			}

			@keyframes dash {
				0% {
					stroke-dasharray: 1, 150;
					stroke-dashoffset: 0;
				}
				50% {
					stroke-dasharray: 90, 150;
					stroke-dashoffset: -35;
				}
				100% {
					stroke-dasharray: 90, 150;
					stroke-dashoffset: -124;
				}
			}
		`;
	}

	#getSizePx(size) {
		return MIN_SIZE_PX + SIZE_STEP_PX * Math.max(0, (size || 0));
	}

	#buildIconsElemFromString(iconsSource) {
		SvgIconElement.#icons = new Map();
		const list = iconsSource.matchAll(/<symbol [^>]*id=["']([^'"]+)["'][^>]*viewbox=["']([^'"]+)["'][^>]*>(.+?)<\/symbol>/sgi);
		for (let [, id, viewBox, content] of list) {
			SvgIconElement.#icons.set(id, {viewBox, content});
		}

		return true;
	}
}

customElements.define('f-icon', SvgIconElement);
