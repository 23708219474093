import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";
import {TexturesFactory} from "ui/components/textures/TexturesFactory";
import {Color} from "../../styles/Color.js";

class ScreenElement extends AbstractCustomElement {
	attrs = {
		padding: 1,
		loading: false,
		aspectRatio: 0,
		width: '',
		height: '',
	};

	/** @type {?Node[]} */
	#initialNodes;

	/** @type {HTMLElement} */
	#loadingEl;

	/** @type {HTMLStyleElement} */
	#initialStyle;

	#initStyleUpdateRequested = false;

	renderAttribute(name) {
		if (name === 'loading') {
			if (!this.#loadingEl) {
				this.#loadingEl = document.createElement('div');
				this.#loadingEl.className = 'loading';
				this.shadowRoot.appendChild(this.#loadingEl);
			}
			return true;
		}

		if ((name === 'width' || name === 'height' || name === 'aspectRatio') && this.#appendInitialStyle) {
			if (!this.#initStyleUpdateRequested) {
				this.#initStyleUpdateRequested = true;
				queueMicrotask(this.#appendInitialStyle.bind(this));
			}
			return true;
		}

		return false;
	}

	async render() {
		this.shadowRoot.innerHTML = '<div class="bg"><div class="body"></div></div>';
		this.#appendInitialStyle();
		await this.#appendStyle();
		this.#appendContent();
	}

	#appendInitialStyle() {
		this.#initStyleUpdateRequested = false;
		let style;

		if (this.#initialStyle) {
			style = this.#initialStyle;
		} else {
			style = document.createElement('style');
		}

		style.textContent = /* css */`
			:host {
				display: block;
				position: relative;
				width: ${this.attrs.width === '' ? '100%' : this.attrs.width + (/\d$/.test(this.attrs.width) ? 'px' : '')};
				${
					this.attrs.aspectRatio > 0
					? `aspect-ratio: ${this.attrs.aspectRatio};`
					: (this.attrs.height === '' ? '100%' : 'height: ' + this.attrs.height + (/\d$/.test(this.attrs.height) ? 'px' : ''))
				}
			}
		`;

		if (this.#initialStyle !== style) {
			this.#initialStyle = style;
			this.shadowRoot.appendChild(this.#initialStyle);
		}
	}

	async #appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();
		const borderRadius = Style.getBorderRadiusPx(2);
		const scale = window.devicePixelRatio > 1 ? 2 : 1;

		const bgs = await Promise.all([
			TexturesFactory.getScratches(),
			TexturesFactory.getLedMatrixBg(scale),
			TexturesFactory.getNoiseTexture(new Color(255, 255, 255), 0.01, 0.1),
		]);

		style.textContent = /* css */`
			.body {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				overflow: hidden;
				border-radius: calc(${borderRadius} - 1px);
				box-sizing: border-box;
				z-index: 4;
			}

			.bg {
				position: absolute;
				z-index: 0;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: ${Style.getIndentPx(this.attrs.padding)};
				border-radius: ${borderRadius};
				box-sizing: border-box;
				background-image: linear-gradient(${colors.bgDark1}, ${colors.bgLight1});
				overflow: hidden;

				&::before {
					content: '';
					position: absolute;
					inset: 1px 1px 1px 1px;
					border-radius: ${borderRadius};
					background-color: ${colors.bgDark2};
					z-index: 2;
				}

				&::after {
					content: '';
					display: flex;
					align-items: center;
					justify-content: center;
					color: ${colors.textDark1};
					text-shadow: 0 0 1px ${colors.glow};
					position: absolute;
					inset: 3px 3px 3px 3px;
					border-radius: ${borderRadius};
					background-color: ${colors.bgDark1};
					background-image: url("${bgs[0]}"), url("${bgs[1]}");
					background-size: auto, 3px 3px;
					background-position: ${Math.ceil(Math.random() * 200)}px ${Math.ceil(Math.random() * 200)}px, 0 0;
					z-index: 3;
					box-shadow:
						0 1px ${colors.bg} inset,
						1px 0 ${colors.bgDark1} inset,
						-1px 0 ${colors.bgDark1} inset,
						0 -1px ${colors.bgDark2} inset;
				}
			}

			:host([loading]:not([loading="false"])) .bg::after {
				content: 'Loading...';
				animation-name: noise-animation;
				animation-duration: 2s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				animation-timing-function: steps(5, jump-start);
				background-image: url("${bgs[2]}"), url("${bgs[0]}"), url("${bgs[1]}");
			}

			:host([loading]:not([loading="false"])) .body {
				visibility: hidden;
			}

			@keyframes noise-animation {
				0% {background-position: 12px 213px, 0 0, 0 0;}
				20% {background-position: 662px 342px, 0 0, 0 0;}
				40% {background-position: 0px 150px, 0 0, 0 0;}
				60% {background-position: 40px 523px, 0 0, 0 0;}
				80% {background-position: 432px 2px, 0 0, 0 0;}
				100% {background-position: 123px 50px, 0 0, 0 0;}
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendContent() {
		if (!this.#initialNodes) {
			this.#initialNodes = [...this.childNodes];
		}

		const cont = this.shadowRoot.querySelector('.body');

		for (const el of this.#initialNodes) {
			if (el instanceof HTMLTemplateElement) {
				cont.appendChild(el.content.cloneNode(true));
			} else {
				cont.appendChild(el);
			}
		}
	}
}

customElements.define('f-screen', ScreenElement);
