var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<main>\n	<div class=\"change-area\" style=\"--x1: 100px; --y1: 100px; --x2: 200px; --y2: 200px\">\n		<div class=\"change-area__shadow\"></div>\n		<div class=\"change-area__head\">\n			<span class=\"change-area__title\"></span>\n			<a class=\"change-area__button\" href=\"#\" target=\"_blank\">\n				<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"svg-icon\">\n					<use href=\"#icon-svg-path\"></use>\n				</svg>\n				<span>"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"tools.tooltips.front-page.source",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":53}}}))
    + "</span>\n			</a>\n			<div class=\"change-area__interactive\">\n				<div class=\"change-area__interactive-button\">\n					"
    + alias2(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.open",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":5},"end":{"line":14,"column":28}}}))
    + "\n				</div>\n			</div>\n		</div>\n	</div>\n</main>";
},"useData":true});