import tpl from "./alert-dialog.handlebars";
import Template from "ui/Template";

export function createErrorAlert(message) {
	return createAlert(message, 'warning', 'danger');
}

export function createFatalErrorAlert(message) {
	return createAlert(message, 'warning', 'danger', false);
}

export function createSuccessAlert(message) {
	return createAlert(message, 'check', 'success');
}

export async function createAlert(message, icon = 'warning', color = 'info', showCloseButton = true) {
	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('width', '400');
	dialog.setAttribute('color', color);
	dialog.setAttribute('unclosable', 'true');
	dialog.setAttribute('modal', '');
	dialog.innerHTML = new Template(tpl).createHtml({color, message, icon, showCloseButton});

	document.body.appendChild(dialog);

	dialog.addEventListener('f-button-click', () => dialog.remove());

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}
