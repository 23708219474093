import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class MessageElement extends AbstractCustomElement {
	attrs = {
		icon: '',
		iconSize: 5,
		closable: false,
		fitContent: false,
		inline: false,
		center: false,
		color: 'face',
		padding: 3,
	}

	/** @type {?Node[]} */
	#initialNodes;

	async render() {
		this.shadowRoot.innerHTML = '';

		const cont = document.createElement('f-box');
		cont.setAttribute('padding', `${this.attrs.padding}`);
		cont.setAttribute('flex', '');
		cont.setAttribute('gap', `${this.attrs.padding}`);
		cont.setAttribute('color', this.attrs.color);
		cont.setAttribute('text-shadow', 'true');

		if (this.attrs.center) {
			cont.setAttribute('justify-content', 'center');
		}

		if (this.attrs.inline) {
			cont.setAttribute('inline', '');
		}

		if (this.attrs.fitContent) {
			cont.setAttribute('fit-content', '');
		}

		this.#appendIcon(cont);
		this.#appendContent(cont);
		this.#appendCloseButton(cont);

		this.shadowRoot.appendChild(cont);
	}

	#appendIcon(cont) {
		if (this.attrs.icon === '') {
			return;
		}

		const ico = document.createElement('f-icon');
		ico.setAttribute('icon', this.attrs.icon);
		ico.setAttribute('size', `${this.attrs.iconSize}`);
		cont.appendChild(ico);
	}

	#appendContent(cont) {
		if (!this.#initialNodes) {
			this.#initialNodes = [...this.childNodes];
		}

		const body = document.createElement('div');

		for (const node of this.#initialNodes) {
			if (node instanceof HTMLTemplateElement) {
				body.appendChild(node.content.cloneNode(true));
			} else {
				body.appendChild(node);
			}
		}

		cont.appendChild(body);
	}

	#appendCloseButton(cont) {
		if (!this.attrs.closable) {
			return;
		}

		const btn = document.createElement('f-button');
		btn.setAttribute('icon', 'close');
		btn.setAttribute('small', '');
		btn.setAttribute('color', this.attrs.color);

		btn.onclick = () => {
			const event = new CustomEvent('close', {cancelable: true});
			this.dispatchEvent(event);
			if (!event.defaultPrevented) {
				this.remove();
			}
		};
		cont.appendChild(btn);
	}
}

customElements.define('f-message', MessageElement);
