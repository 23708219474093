import Utils from "../../../helpers/Utils.js";

export async function createImageDialog(title, url) {
	const screenEl = document.createElement('f-screen');
	screenEl.setAttribute('loading', 'true');
	screenEl.setAttribute('width', '100%');
	screenEl.setAttribute('height', '200');

	const anchorEl = document.createElement('a');
	anchorEl.target = '_blank';
	anchorEl.href = url;

	const img = new Image();
	anchorEl.appendChild(img);
	screenEl.appendChild(anchorEl);

	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('caption', title);
	dialog.setAttribute('width', '300');
	dialog.setAttribute('modal', '');
	dialog.setAttribute('padding', '1');
	dialog.appendChild(screenEl);

	document.body.appendChild(dialog);

	// wait until all custom elements will be appeared and for proper UI effect
	await Utils.wait(500);

	img.onload = () => {
		const k = Math.min(
			Math.min(img.naturalWidth, window.innerWidth * 0.8) / img.naturalWidth,
			Math.min(img.naturalHeight, window.innerHeight * 0.8) / img.naturalHeight
		);

		const newWidth = Math.floor(img.naturalWidth * k);
		const newHeight = Math.floor(img.naturalHeight * k);
		img.width = newWidth;
		screenEl.setAttribute('width', `${newWidth + 10}`);
		screenEl.setAttribute('height', `${newHeight + 10}`);

		dialog.setAttribute('width', `${newWidth + 40}`);

		screenEl.removeAttribute('loading');
	};
	img.src = url;

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}
