import {Style} from "ui/components/styles/Style";
import {AbstractTexture} from "./AbstractTexture.js";
/**
 * @typedef {object} LedMatrixTextureOptions
 * @property {string} [linesColor]
 * @property {number} [scale]
 */

export class LedMatrixTexture extends AbstractTexture {
	/** @type {LedMatrixTextureOptions} */
	#options = {
		linesColor: '',
		scale: 1,
	};

	constructor() {
		super();

		const colorsSet = Style.getColorsSet();
		this.#options.linesColor = colorsSet.bgDark2.cssValue;
	}

	/**
	 * @param {LedMatrixTextureOptions} options
	 * @returns {LedMatrixTexture}
	 */
	setOptions(options) {
		Object.assign(this.#options, options);
		return this;
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 * @param {number} height
	 */
	renderTexture(ctx, width, height) {
		ctx.save();

		const cellSize = 2 * this.#options.scale;
		const steps = Math.floor(width / cellSize);
		let v;

		ctx.strokeStyle = this.#options.linesColor;
		ctx.lineWidth = this.#options.scale;

		ctx.beginPath();
		for (let i = 0; i < steps; i++) {
			v = (i + 1) * cellSize - (this.#options.scale + 0.5);
			ctx.moveTo(v, 0);
			ctx.lineTo(v, height);
			ctx.moveTo(0, v);
			ctx.lineTo(width, v);
		}
		ctx.stroke();

		ctx.restore();
	}
}
