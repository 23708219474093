import {AbstractTexture} from "./AbstractTexture.js";

const MIN_NOISE_ALPHA = 0;
const MAX_NOISE_ALPHA = 20;

const POINTS_SECTION_SIZE = 50;
const MIN_POINTS_IN_SEC = 5;
const MAX_POINTS_IN_SEC = 10;
const MIN_POINT_ALPHA = 20;
const MAX_POINT_ALPHA = 30;

const SCRATCHES_SECTION_SIZE = 120;
const MIN_SCRATCHES_IN_SEC = 4;
const MAX_SCRATCHES_IN_SEC = 15;
const MIN_SCRATCHES_ALPHA = 3;
const MAX_SCRATCHES_ALPHA = 8;
const MIN_SCRATCHES_LENGTH = 5;
const MAX_SCRATCHES_LENGTH = 50;

const POINT_SHAPES = [
	[
		0, 0, 0,
		0, 1, 0,
		0, 2, 0,
	],
	[
		0, 2, 0,
		0, 1, 0,
		0, 0, 0,
	],
	[
		0, 0, 0,
		1, 1, 0,
		2, 2, 0,
	],
	[
		0, 0, 1,
		1, 1, 2,
		2, 2, 0,
	],
	[
		0, 0, 2,
		2, 2, 1,
		1, 1, 0,
	],
	[
		0, 0, 0,
		1, 1, 1,
		1, 2, 2,
	],
	[
		1, 0, 0,
		2, 1, 0,
		0, 2, 1,
	],
	[
		0, 0, 1,
		0, 1, 2,
		1, 2, 0,
	],
	[
		1, 0, 1,
		2, 1, 2,
		0, 2, 0,
	],
	[
		0, 1, 0,
		1, 1, 0,
		2, 2, 0,
	],
	[
		2, 1, 0,
		1, 1, 1,
		0, 0, 0,
	],
];

/**
 * @typedef {object} ScratchesTextureOptions
 * @property {boolean} [noise=true]
 * @property {boolean} [dots=true]
 * @property {boolean} [scratches=true]
 */

export class ScratchesTexture extends AbstractTexture {
	/** @type {ScratchesTextureOptions} */
	#options = {
		noise: true,
		dots: true,
		scratches: true,
	};

	/**
	 * @param {ScratchesTextureOptions} options
	 * @returns {ScratchesTexture}
	 */
	setOptions(options) {
		Object.assign(this.#options, options);
		return this;
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 * @param {number} height
	 */
	renderTexture(ctx, width, height) {
		this.#renderNoise(ctx, width, height);
		this.#renderScratches(ctx, width, height);
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 */
	#renderNoise(ctx, width, height) {
		const imgData = new ImageData(width, height);
		const rgba = imgData.data;

		const cols = Math.ceil(width / POINTS_SECTION_SIZE);
		const rows = Math.ceil(height / POINTS_SECTION_SIZE);
		let i, j, sr, sc, alpha, shape, color, maxI = rgba.length;

		if (this.#options.noise) {
			for (let i = 3, to = rgba.length; i < to; i += 4) {
				rgba[i] = this.getPseudoRandomNumber(MIN_NOISE_ALPHA, MAX_NOISE_ALPHA);
			}
		}

		if (this.#options.dots) {
			for (let r = 0; r < rows; r++) {
				const ry = r * POINTS_SECTION_SIZE;
				for (let c = 0; c < cols; c++) {
					const cx = c * POINTS_SECTION_SIZE;

					// get count of points inside one section
					let count = this.getPseudoRandomNumber(MIN_POINTS_IN_SEC, MAX_POINTS_IN_SEC);

					while (count-- >= 0) {
						// get position of the point inside section square
						sc = this.getPseudoRandomNumber(0, POINTS_SECTION_SIZE);
						sr = this.getPseudoRandomNumber(0, POINTS_SECTION_SIZE);
						alpha = this.getPseudoRandomNumber(MIN_POINT_ALPHA, MAX_POINT_ALPHA);
						shape = POINT_SHAPES[this.getPseudoRandomNumber(0, POINT_SHAPES.length - 1)];

						// put shadow pixel in rgba array
						i = ((ry + sr) * width + cx + sc) << 2;
						j = 0;
						for (let y = 0; y < 3; y++) {
							for (let x = 0; x < 3; x++) {
								if (i < maxI) {
									color = shape[j];
									if (color === 1) {
										rgba[i + 3] = alpha;
									} else if (color === 2) {
										rgba[i] = 255;
										rgba[i + 1] = 255;
										rgba[i + 2] = 255;
										rgba[i + 3] = alpha >> 1;
									}
								}

								i += 4;
								j++;
							}

							i -= 12; // shift back: 4 channels * 3 pixels
							i += width << 2; // move to next row
						}
					}
				}
			}
		}

		ctx.putImageData(imgData, 0, 0);
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 */
	#renderScratches(ctx, width, height) {
		if (!this.#options.scratches) {
			return;
		}

		const cols = Math.ceil(width / SCRATCHES_SECTION_SIZE);
		const rows = Math.ceil(height / SCRATCHES_SECTION_SIZE);
		let centerX, centerY, angle, len, alpha, radiusX, radiusY, grad, rnd;

		ctx.save();
		ctx.lineWidth = 1;

		for (let r = 0; r < rows; r++) {
			const ry = r * SCRATCHES_SECTION_SIZE;
			for (let c = 0; c < cols; c++) {
				const cx = c * SCRATCHES_SECTION_SIZE;

				// get count of scratches inside one section
				let count = this.getPseudoRandomNumber(MIN_SCRATCHES_IN_SEC, MAX_SCRATCHES_IN_SEC);

				while (count-- >= 0) {
					// get center position of the scratch inside section square
					centerX = cx + this.getPseudoRandomNumber(0, SCRATCHES_SECTION_SIZE);
					centerY = ry + this.getPseudoRandomNumber(0, SCRATCHES_SECTION_SIZE);
					angle = this.getPseudoRandomNumber(0, 360) / 180 * Math.PI;
					len = this.getPseudoRandomNumber(MIN_SCRATCHES_LENGTH, MAX_SCRATCHES_LENGTH) / 2;
					alpha = this.getPseudoRandomNumber(MIN_SCRATCHES_ALPHA, MAX_SCRATCHES_ALPHA) / 100;
					radiusX = Math.sin(angle) * len;
					radiusY = -Math.cos(angle) * len;
					rnd = this.getPseudoRandomNumber(1, 4);

					grad = ctx.createRadialGradient(centerX, centerY, 2, centerX, centerY, len);
					grad.addColorStop(0, `rgba(255, 255, 255, ${alpha})`);
					grad.addColorStop(1, 'rgba(255, 255, 255, 0)');

					ctx.beginPath();
					ctx.strokeStyle = grad;
					ctx.moveTo(centerX - radiusX - 1, centerY - radiusY + 1);
					ctx.lineTo(centerX + radiusX - 1, centerY + radiusY + 1);
					ctx.stroke();

					if (rnd > 1) {
						grad = ctx.createRadialGradient(centerX, centerY, 2, centerX, centerY, len);
						grad.addColorStop(0, `rgba(0, 0, 0, ${alpha})`);
						grad.addColorStop(1, 'rgba(0, 0, 0, 0)');

						ctx.beginPath();
						ctx.strokeStyle = grad;
						ctx.moveTo(centerX - radiusX, centerY - radiusY);
						ctx.lineTo(centerX + radiusX, centerY + radiusY);
						ctx.stroke();
					}
				}
			}
		}

		ctx.restore();
	}
}
