import {Style} from "ui/components/styles/Style";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

class BadgeElement extends AbstractCustomElement {
	attrs = {
		color: 'danger',
		padding: 1,
	};

	/** @type {?Node[]} */
	#initialNodes;

	async render() {
		this.shadowRoot.innerHTML = '<div></div>';
		this.#appendStyle();
		this.#appendContent();
	}

	#appendStyle() {
		const style = document.createElement('style');
		const colors = Style.getColorsSet();
		const mainColors = Style.getColorsSet(this.attrs.color);

		// language=less
		style.textContent = `
			:host {
				--shadow-color: ${mainColors.textShadow};

				display: flex;
				align-items: center;
				justify-content: center;
				padding: ${Style.getIndentPx(this.attrs.padding)};
				border-radius: ${Style.getBorderRadiusPx(2)};
				box-sizing: border-box;
				color: ${mainColors.text};
				background-image: linear-gradient(${mainColors.bgLight1} 30%, ${mainColors.bg});
				border-top: 1px solid ${mainColors.bgLight2};
				border-bottom: 1px solid ${mainColors.bgDark1};
				box-shadow: 0 1px 2px ${colors.bgDark2};
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#appendContent() {
		if (!this.#initialNodes) {
			this.#initialNodes = [...this.childNodes];
		}

		for (const el of this.#initialNodes) {
			this.shadowRoot.appendChild(el);
		}
	}
}

customElements.define('f-badge', BadgeElement);
