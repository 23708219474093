import {Style} from "ui/components/styles/Style";
import {AbstractTexture} from "./AbstractTexture.js";
/**
 * @typedef {object} LinesTextureOptions
 * @property {string} [linesColor]
 * @property {number} [step]
 * @property {boolean} [horizontal]
 */

export class LinesTexture extends AbstractTexture {
	/** @type {LinesTextureOptions} */
	#options = {
		linesColor: '',
		horizontal: false,
		step: 2,
	};

	constructor() {
		super();

		const colorsSet = Style.getColorsSet();
		this.#options.linesColor = colorsSet.bgDark2.cssValue;
	}

	/**
	 * @param {LinesTextureOptions} options
	 * @returns {LinesTexture}
	 */
	setOptions(options) {
		Object.assign(this.#options, options);
		return this;
	}

	/**
	 * @param {CanvasRenderingContext2D} ctx
	 * @param {number} width
	 * @param {number} height
	 */
	renderTexture(ctx, width, height) {
		ctx.save();

		const steps = Math.floor((this.#options.horizontal ? width : height) / this.#options.step);

		ctx.strokeStyle = this.#options.linesColor;
		ctx.lineWidth = 1;

		ctx.beginPath();

		if (this.#options.horizontal) {
			for (let i = 0; i < steps; i++) {
				let x = this.#options.step * (i + 1) - 0.5;
				ctx.moveTo(x, 0);
				ctx.lineTo(x, height);
			}
		} else {
			for (let i = 0; i < steps; i++) {
				let y = this.#options.step * (i + 1) - 0.5;
				ctx.moveTo(0, y);
				ctx.lineTo(width, y);
			}
		}

		ctx.stroke();

		ctx.restore();
	}
}
