import {
	IS_HIGH_DPR, IS_SMALL_VIEW, IS_WEBP_SUPPORTED,
	SCENE_FPS,
	SCENE_FRAMES,
	SCENE_SLOT_HEIGHT, SCENE_SLOT_WIDTH,
	STATIC_URL,
} from "ui/config";
import AbstractMatrixView from "ui/front/matrix/AbstractMatrixView";
import CanvasViewSlot from "ui/front/matrix/CanvasViewSlot";
import CanvasViewAddon from "ui/front/matrix/CanvasViewAddon";
import {formatFileSize} from "ui/helpers/formatters";

const ONE_FRAME_DURATION = ~~(1000 / SCENE_FPS);
const FPS_BUFFER_MAX_DURATION = 400;
const FPS_BUFFER_LENGTH = 180;
const FPS_BUFFER_REPORT_MIN_LENGTH = 120;
const FPS_BUFFER_SPLICE = 20;

const VIBRATE_SCENE_OFFSETS = [-2, 2, -1, 1];

let lastFps = [];

export default class CanvasMatrixView extends AbstractMatrixView {
	/**
	 * @param {T_AbstractMatrixViewOptions} options
	 */
	constructor(options) {
		super(options);

		/**
		 * @type {CanvasViewSlot[]}
		 * @private
		 */
		this._slots = [];

		/**
		 * @type {CanvasViewSlot[]}
		 * @private
		 */
		this._displayList = [];

		/**
		 * @type {CanvasViewAddon[]}
		 * @private
		 */
		this._addons = [];

		/**
		 * @type {CanvasViewAddon[]}
		 * @private
		 */
		this._addonsDisplayList = [];

		/**
		 * @type {{canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D}}
		 * @private
		 */
		this._canvas = this._createCanvas();

		/**
		 * @type {HTMLDivElement}
		 * @private
		 */
		this._matrixContainer = document.createElement('div');
		this._matrixContainer.setAttribute('inert', '');
		this._matrixContainer.className = 'slots-matrix';

		/**
		 * @type {boolean}
		 * @private
		 */
		this._newFrameIsReady = true;

		/**
		 * @type {number}
		 * @private
		 */
		this._requestedNextFrame = -1;

		this._renderBound = this._render.bind(this);

		/**
		 * @type {number}
		 * @private
		 */
		this._lastRenderTime = 0;

		/**
		 * @type {number[]}
		 * @private
		 */
		this._renderOffset = [];

		this._matrixPosition.addObserver(() => {
			this._updateDisplayList();
			this._updateAddonsDisplayList();
			this._renderDisplayList();
			this._updateMatrixContainer();
		});

		window.addEventListener('resize', this._updateSize.bind(this));
		window.addEventListener('orientationchange', this._updateSize.bind(this));

		document.addEventListener('vibrate-scene', () => {
			this._renderOffset = [...VIBRATE_SCENE_OFFSETS];
		});

		document.addEventListener('add-render-slot', /** @param {CustomEvent} e */ (e) => {
			this._slots.push(new CanvasViewSlot(this._matrixPosition, e.detail));
			this._updateDisplayList();
		});

		document.addEventListener('jump-frame', /** @param {CustomEvent} e */ (e) => {
			this._requestedNextFrame = parseInt(e.detail) - 1;
			if (this._requestedNextFrame < 0) {
				this._requestedNextFrame = SCENE_FRAMES - 1;
			}
		});

		document.addEventListener('delete-slots-by-url-pattern', /** @param {CustomEvent} e */ (e) => {
			/** @type {RegExp} */
			const testUrlRegExp = e.detail;
			this._slots = this._slots.filter(slot => {
				if (testUrlRegExp.test(slot.getUrl())) {
					slot.unload();
					console.log('remove slot ' + slot.getUrl());
					return false;
				}
				return true;
			});
			this._updateDisplayList();
		});
	}

	static getAvgFps() {
		if (lastFps.length < FPS_BUFFER_REPORT_MIN_LENGTH) {
			return 0;
		}

		const avgFrameTime = lastFps.reduce((acc, v) => acc + v, 0) / lastFps.length;

		return Math.round(1000 / avgFrameTime);
	}

	/**
	 * @return {Promise<void>}
	 */
	async build() {
		await super.build();
		this._buildMatrix();
		this._buildAddonsList();
		this._updateSize();
		this._element.appendChild(this._matrixContainer);
		this._element.appendChild(this._canvas.canvas);

		this._matrixPosition.init(
			this._options.restorePositionFromUrl,
			this._options.restorePositionFromLS,
		);
		this._updateDisplayList();
		this._updateAddonsDisplayList();
		this._updateMatrixContainer();
	}

	play() {
		super.play();
		this._updateDisplayList();
		this._updateAddonsDisplayList();
		this._render();
	}

	stop() {
		super.stop();
	}

	/**
	 * @param {number} factor
	 * @param {number} x
	 * @param {number} y
	 */
	changeZoomFactor(factor, x, y) {
		if (this._options.zoomFactor === factor) {
			return;
		}

		super.changeZoomFactor(factor, x, y);
		this._updateMatrixContainer();
	}

	/**
	 * @private
	 */
	_render() {
		cancelAnimationFrame(this._renderBound);

		if (this._stopped) {
			this._renderDisplayList();
			return;
		}

		const timeDelta = Date.now() - this._lastRenderTime;

		if (timeDelta < ONE_FRAME_DURATION || !this._newFrameIsReady) {
			requestAnimationFrame(this._renderBound);
			return;
		}

		if (timeDelta < FPS_BUFFER_MAX_DURATION) {
			lastFps.push(timeDelta);

			if (lastFps.length === FPS_BUFFER_LENGTH) {
				lastFps.splice(0, FPS_BUFFER_SPLICE);
			}
		}

		this._lastRenderTime = Date.now();
		this._renderDisplayList();

		this._requestNextFrame().then(() => {
			requestAnimationFrame(this._renderBound);
		});
	}

	/**
	 * @private
	 */
	_drawBackground() {
		const ctx = this._canvas.ctx;
		ctx.save();
		ctx.fillStyle = '#373737';
		ctx.fillRect(0, 0, this._canvas.canvas.width, this._canvas.canvas.height);
		ctx.restore();
	}

	/**
	 * @private
	 */
	_renderDisplayList() {
		const ctx = this._canvas.ctx;

		const isSmooth = (this._matrixPosition.zoomFactor | 0) !== this._matrixPosition.zoomFactor;
		ctx.imageSmoothingEnabled = isSmooth;
		ctx.webkitImageSmoothingEnabled = isSmooth;
		ctx.mozImageSmoothingEnabled = isSmooth;
		ctx.imageSmoothingQuality = 'high';

		this._drawBackground();

		if (this._renderOffset.length > 0) {
			ctx.save();
			ctx.translate(this._renderOffset[0], 0);
		}

		for (const slot of this._displayList) {
			slot.render(ctx);
		}

		if (this._renderOffset.length > 0) {
			this._renderOffset.shift();
			ctx.restore();
		}

		for (const addon of this._addonsDisplayList) {
			addon.render(ctx, this._frame);
		}

	}

	/**
	 * @return {Promise<void>}
	 * @private
	 */
	async _requestNextFrame() {
		this._newFrameIsReady = false;
		if (this._requestedNextFrame > -1) {
			this._frame = this._requestedNextFrame;
			this._requestedNextFrame = -1;
		} else {
			this._frame = (this._frame + 1) % SCENE_FRAMES;
		}

		this._options.onFrame && this._options.onFrame(this._frame);

		await Promise.all(this._displayList.map(slot => slot.prepare(this._frame)))

		this._newFrameIsReady = true;
	}

	/**
	 * @return {{canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D}}
	 * @private
	 */
	_createCanvas() {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d', {alpha: false});
		ctx.imageSmoothingEnabled = false;
		ctx.webkitImageSmoothingEnabled = false;
		ctx.mozImageSmoothingEnabled = false;
		return {canvas, ctx};
	}

	/**
	 * @private
	 */
	_updateDisplayList() {
		const newList = [];
		for (const slot of this._slots) {
			if (slot.isVisible()) {
				newList.push(slot);
				if (!slot.isLoaded()) {
					slot.load().then();
				}
			} else if (this._displayList.includes(slot)) {
				slot.unload();
			}
		}
		this._displayList = newList;
	}

	/**
	 * @private
	 */
	_updateAddonsDisplayList() {
		const newList = [];
		for (const addon of this._addons) {
			if (addon.isVisible()) {
				newList.push(addon);
				if (!addon.isLoaded()) {
					addon.load().then();
				}
			} else if (this._addonsDisplayList.includes(addon)) {
				addon.unload();
			}
		}
		this._addonsDisplayList = newList;
	}

	/**
	 * @private
	 */
	_updateSize() {
		const scale = IS_HIGH_DPR ? 1 : 0;
		this._canvas.canvas.width = this._matrixPosition.width << scale;
		this._canvas.canvas.height = this._matrixPosition.height << scale;
		this._canvas.canvas.style.width = `${this._matrixPosition.width}px`;
		this._canvas.canvas.style.height = `${this._matrixPosition.height}px`;

		this._updateDisplayList();
		this._updateAddonsDisplayList();
		this._updateMatrixContainer();
		this._renderDisplayList();
	}

	/**
	 * @private
	 */
	_updateMatrixContainer() {
		this._matrixContainer.style.setProperty('--width', `${SCENE_SLOT_WIDTH * this._options.zoomFactor}px`);
		this._matrixContainer.style.setProperty('--height', `${SCENE_SLOT_HEIGHT * this._options.zoomFactor}px`);
		this._matrixContainer.scrollLeft = this._matrixPosition.x;
		this._matrixContainer.scrollTop = this._matrixPosition.y;
	}

	/**
	 * @private
	 */
	_buildMatrix() {
		const {mat} = this._matrixData;

		const matrixBySlots = [];
		for (let r = 0; r < mat.length; r++) {
			const line1 = [];
			const line2 = [];

			for (let c = 0; c < mat[r].length; c++) {
				const cell = mat[r][c];
				const files = [
					[
						{id: `${cell.id}_0_0`, row: 0, col: 0, file: '', size: 0, preview: ''},
						{id: `${cell.id}_0_1`, row: 0, col: 1, file: '', size: 0, preview: ''},
					],
					[
						{id: `${cell.id}_1_0`, row: 1, col: 0, file: '', size: 0, preview: ''},
						{id: `${cell.id}_1_1`, row: 1, col: 1, file: '', size: 0, preview: ''},
					]
				];
				let i = 0;
				for (const filePath of cell.video.f796) {
					const m = filePath.match(/-(\d+)-(\d+)\.f796/);
					const obj = files[parseInt(m[1])][parseInt(m[2])];
					if (!obj) {
						continue;
					}
					obj.file = STATIC_URL + '/data/' + filePath + (!/\.br$/.test(filePath) ? '.br' : '');
					obj.size = cell.video_size?.f796[i++] || 0;
					obj.preview = STATIC_URL + '/data/' + (this._options.useQualityPreview ? cell.preview_q : cell.preview);
				}
				line1.push(files[0][0]);
				line1.push(files[0][1]);

				line2.push(files[1][0]);
				line2.push(files[1][1]);
			}

			matrixBySlots.push(line1);
			matrixBySlots.push(line2);
		}

		this._matrixContainer.style.setProperty('--columns', matrixBySlots[0].length);

		for (let r = 0; r < matrixBySlots.length; r++) {
			const rowDiv = document.createElement('div');
			rowDiv.className = 'slots-matrix__row';

			for (let c = 0; c < matrixBySlots[r].length; c++) {
				const cell = matrixBySlots[r][c];
				const cellDiv = document.createElement('div');

				cellDiv.className = 'slots-matrix__cell';
				cellDiv.setAttribute('data-slot-id', cell.id);
				cellDiv.setAttribute('data-slot-size', formatFileSize(cell.size));
				cellDiv.setAttribute(
					'data-preview',
					IS_WEBP_SUPPORTED ? cell.preview : cell.preview.replace(/\.webp$/, '.jpg')
				);
				cellDiv.style.setProperty('--col', cell.col);
				cellDiv.style.setProperty('--row', cell.row);
				cellDiv.textContent = `(loading)`;
				rowDiv.appendChild(cellDiv);

				if (cell.file) {
					const slot = new CanvasViewSlot(
						this._matrixPosition,
						{
							x: c * SCENE_SLOT_WIDTH,
							y: r * SCENE_SLOT_HEIGHT,
							width: SCENE_SLOT_WIDTH,
							height: SCENE_SLOT_HEIGHT,
							url: cell.file,
							debugDiv: cellDiv
						}
					);
					this._slots.push(slot);
				}
			}

			this._matrixContainer.appendChild(rowDiv);
		}
	}

	/**
	 * @private
	 */
	_buildAddonsList() {
		for (const {pts, url, cond} of this._matrixData.addons) {
			this._addons.push(new CanvasViewAddon(pts, this._matrixPosition, url, this._slots, cond));
		}
	}
}
