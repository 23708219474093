import {i18n} from "ui/i18n";
import aboutTemplate from "./about.handlebars";
import Template from "ui/Template";
import {STATIC_URL} from "ui/config";
import {TARGETS} from "ui/front/websocket/enum";
import {formatFileSize} from "ui/helpers/formatters.js";

/**
 * @param {AbstractMatrixView} matrix
 * @param {StatClient} stat
 * @return {Promise<void>}
 */
export async function createAboutDialog(matrix, stat) {
	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('caption', i18n('title.about'));
	dialog.setAttribute('width:by-window-width', '{850: 500, max: 850}');
	dialog.setAttribute('modal', '');
	dialog.innerHTML = new Template(aboutTemplate).createHtml({
		staticUrl: STATIC_URL,
		images: matrix.matrixData.fullsizeFiles.map(([file, size], i) => ({
			index: i + 1,
			url: STATIC_URL + '/data/fullsize/' + file,
			size: formatFileSize(size)
		}))
	});

	dialog.addEventListener('f-button-click', (e) => {
		const cmd = e.detail.getAttribute('onclick');
		if (!cmd) {
			return;
		}

		if (cmd.includes('editor/l0')) {
			stat.saveClickByTarget(TARGETS.LINK_EDITOR);
		} else if (cmd.includes('youtube.com')) {
			stat.saveClickByTarget(TARGETS.LINK_YOUTUBE);
		} else if (cmd.includes('habr.com')) {
			stat.saveClickByTarget(TARGETS.LINK_HABR);
		} else if (cmd.includes('artstation')) {
			stat.saveClickByTarget(TARGETS.LINK_ARTSTATION);
		} else if (cmd.includes('reddit')) {
			stat.saveClickByTarget(TARGETS.LINK_REDDIT);
		} else if (cmd.includes('x.com')) {
			stat.saveClickByTarget(TARGETS.LINK_TWITTER);
		} else if (cmd.includes('t.me')) {
			stat.saveClickByTarget(TARGETS.LINK_TELEGRAM);
		} else if (cmd.includes('pikabu.ru')) {
			stat.saveClickByTarget(TARGETS.LINK_PIKABU);
		} else if (cmd.includes('fullsize')) {
			stat.saveClickByTarget(TARGETS.DIALOG_DOWNLOAD_IMAGES);
		}
	});

	document.body.appendChild(dialog);

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}
