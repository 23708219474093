var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<f-box padding=\"1\">\n	<f-box color=\"face\" padding=\"3\" margin-bottom=\"2\" style=\"text-wrap: balance; text-align: center\">\n		"
    + ((stack1 = __default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"hints.subs",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":25}}})) != null ? stack1 : "")
    + "\n	</f-box>\n	<f-message color=\"danger\" icon=\"warning\" show:if=\"!!state(this).error\">\n		<f-slot value:from=\"state(this).error\"></f-slot>\n	</f-message>\n	<f-box flex gap=\"2\" justify-content=\"center\" margin-top=\"3\">\n		<f-input-text placeholder=\"your@email.com\" disabled:if=\"state(this).inProgress\" state-field=\"state(this).email\"></f-input-text>\n		<f-bulb color=\"danger\" blink:if=\"state(this).inProgress\"></f-bulb>\n		<f-button color=\"success\" disabled:if=\"state(this).inProgress\" icon=\"envelope\" data-role=\"submit\" caption=\""
    + container.escapeExpression(__default(require("../../../handlebars-helpers/i18n.js")).call(alias1,"buttons.subscribe",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":109},"end":{"line":11,"column":137}}}))
    + "\"></f-button>\n	</f-box>\n	<f-box flex gap=\"2\" justify-content=\"center\" margin-top=\"3\" margin-bottom=\"2\">\n		<f-button icon=\"telegram\" icon-size=\"4\" color=\"info\" caption=\"Telegram\" onclick=\"window.open('https://t.me/floor796')\"></f-button>\n		<f-button icon=\"x\" icon-size=\"4\" color=\"light\" caption=\"X (Twitter)\" onclick=\"window.open('https://x.com/Floor_796')\"></f-button>\n	</f-box>\n</f-box>\n";
},"useData":true});