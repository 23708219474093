import {Style} from "ui/components/styles/Style";
import {TexturesFactory} from "../../textures/TexturesFactory.js";
import {AbstractCustomElement} from "../AbstractCustomElement.js";

const DASHED_BORDER_SIZE = 14;
const RIDGE_BORDER_RADIUS = 2;

export class BoxElement extends AbstractCustomElement {
	attrs = {
		range: '',
		color: '',
		inline: false,
		flex: false,
		flexMax: false,
		column: false,
		alignItems: 'center',
		justifyContent: '',
		fontSize: 'default',
		width: '',
		height: '',
		xScroll: '',
		yScroll: '',
		square: false,
		gap: 0,
		padding: -1,
		paddingRight: -1,
		paddingLeft: -1,
		paddingTop: -1,
		paddingBottom: -1,
		margin: -1,
		marginRight: -1,
		marginLeft: -1,
		marginTop: -1,
		marginBottom: -1,
		noLightBorders: false,
		textShadow: false,
	}

	/** @type {?Node[]} */
	#initialNodes;

	constructor() {
		super();

		this.shadowRoot.addEventListener('f-visibility-change', /** @param {CustomEvent} e */(e) => {
			if (e.detail && !this.#initialNodes) {
				// make lazy render of the content
				this.#appendContent();
			}
		});
	}

	async render() {
		this.shadowRoot.innerHTML = '';
		await this.#appendStyle();

		if (this.style.display !== 'none') {
			this.#appendContent();
		}
	}

	/**
	 * @protected
	 * @return {HTMLElement|null}
	 */
	getContentWrapper() {
		return null;
	}

	/**
	 * @protected
	 * @return {string}
	 */
	getStyle() {
		return '';
	}

	async #appendStyle() {
		const style = document.createElement('style');
		const colorsSet = Style.getColorsSet(this.attrs.color);

		let borderStyle = '';
		let fillStyle = '';
		let padReducer = 0;
		let addBgNoise = true;

		switch (this.attrs.color) {
			case 'default':
			case 'light':
			case 'face': {
				const size = this.attrs.square ? 0 : Style.getBorderRadiusDec(RIDGE_BORDER_RADIUS);
				if (this.attrs.noLightBorders) {
					borderStyle = /* css */`
						:host {
							border: 1px solid ${colorsSet.bgDark2};
							box-shadow:
								1px -1px 1px ${colorsSet.bgDark1},
								1px -1px 1px ${colorsSet.bgDark1} inset;
							border-radius: ${size}px;
						}
					`;
				} else {
					borderStyle = /* css */`
						:host {
							border: 1px solid ${colorsSet.bgDark2};
							box-shadow:
								-1px 1px 1px ${colorsSet.bgLight1},
								1px -1px 1px ${colorsSet.bgDark1},
								-1px 1px 1px ${colorsSet.bgLight1} inset,
								1px -1px 1px ${colorsSet.bgDark1} inset;
							border-radius: ${size}px;
						}
					`;
				}
				break;
			}
			case 'area':
			case 'success':
			case 'danger':
			case 'warning':
			case 'info': {
				const size = DASHED_BORDER_SIZE;
				const scale = window.devicePixelRatio > 1 ? 2 : 1;
				padReducer = -size;
				addBgNoise = false;
				borderStyle = /* css */`
					:host {
						border: ${size}px solid transparent;
						border-image: url("${
							await TexturesFactory.getWarningBorder(size, scale, this.attrs.color, '', this.attrs.color)
						}") ${size * scale} round;

						border-radius: ${size}px;
						background-clip: padding-box;
						position: relative;
					}

					:host::before {
						content: '';
						position: absolute;
						pointer-events: none;
						left: -${size}px;
						top: -${size}px;
						width: 100%;
						height: 100%;

						border: ${size}px solid transparent;
						border-image: url("${await TexturesFactory.getWarningBorder(
							size,
							scale,
							this.attrs.color,
							colorsSet.bgLight2,
							'',
							this.attrs.color
						)}") ${size * scale} space;
						border-radius: ${size}px;
						background-clip: padding-box;
					}

				`;
				break;
			}
		}

		if (this.attrs.color !== '') {
			fillStyle = /* css */`
				:host {
					color: ${colorsSet.text};
					background-color: ${colorsSet.bg};
					background-image: url("${await TexturesFactory.getScratches(addBgNoise)}");
					background-position:
						calc(${Math.ceil(Math.random() * 200)}px - var(--scroll-x, 0px))
						calc(${Math.ceil(Math.random() * 200)}px - var(--scroll-y, 0px));
				}
			`
		}

		let widthRules = '';

		switch (this.attrs.width) {
			case 'fit-content':
				widthRules = 'width: fit-content;';
				break;
			case '100%':
				const margin = Style.getIndentPx(this.attrs.marginRight)
					+ ' + ' + Style.getIndentPx(this.attrs.marginLeft)
					+ ' + calc(2 * ' + Style.getIndentPx(this.attrs.margin) + ')';
				widthRules = `width: calc(100% - (${margin}));`;
				break;
		}

		let heightRules = '';
		if (this.attrs.height !== '') {
			const margin = Style.getIndentPx(this.attrs.marginTop)
				+ ' + ' + Style.getIndentPx(this.attrs.marginBottom)
				+ ' + calc(2 * ' + Style.getIndentPx(this.attrs.margin) + ')';
			const units = /^[\d.]+$/.test(this.attrs.height) ? 'px' : '';
			heightRules = `height: calc(${this.attrs.height}${units} - ${margin});`;
		}

		style.textContent = /* css */`
			:host {
				display: ${this.#getDisplayMode()};
				box-sizing: border-box;
				${widthRules}
				${heightRules}
				${this.attrs.flex ? `align-items: ${this.attrs.alignItems};` : ''}
				${this.attrs.justifyContent ? `justify-content: ${this.attrs.justifyContent};` : ''}
				${this.attrs.flexMax ? `flex: 1;` : ''}
				${this.attrs.gap > 0 ? `gap: ${Style.getIndentPx(this.attrs.gap)};` : ''}
				${this.attrs.column ? `flex-direction: column;` : ''}
				${this.attrs.fontSize !== '' ? `font-size: ${Style.getFontSizePx(this.attrs.fontSize)};` : ''}
				${this.attrs.textShadow ? `text-shadow: -1px 1px 0 ${colorsSet.textShadow};` : ''}

				${this.attrs.padding > -1 ? `padding: ${Style.getIndentPx(this.attrs.padding, padReducer)};` : ''}
				${
					this.attrs.paddingRight > -1
					? `padding-right: ${Style.getIndentPx(this.attrs.paddingRight, padReducer)};`
					: ''
				}
				${
					this.attrs.paddingLeft > -1
					? `padding-left: ${Style.getIndentPx(this.attrs.paddingLeft, padReducer)};`
					: ''
				}
				${
					this.attrs.paddingBottom > -1
					? `padding-bottom: ${Style.getIndentPx(this.attrs.paddingBottom, padReducer)};`
					: ''
				}
				${
					this.attrs.paddingTop > -1
					? `padding-top: ${Style.getIndentPx(this.attrs.paddingTop, padReducer)};`
					: ''
				}

				${this.attrs.margin > -1 ? `margin: ${Style.getIndentPx(this.attrs.margin)};` : ''}
				${this.attrs.marginRight > -1 ? `margin-right: ${Style.getIndentPx(this.attrs.marginRight)};` : ''}
				${this.attrs.marginLeft > -1 ? `margin-left: ${Style.getIndentPx(this.attrs.marginLeft)};` : ''}
				${this.attrs.marginBottom > -1 ? `margin-bottom: ${Style.getIndentPx(this.attrs.marginBottom)};` : ''}
				${this.attrs.marginTop > -1 ? `margin-top: ${Style.getIndentPx(this.attrs.marginTop)};` : ''}
			}

			${borderStyle}
			${fillStyle}
			${this.attrs.xScroll !== '' || this.attrs.yScroll !== '' ? await this.#getScrollStyle() : ''}
			${this.getStyle()}

			:host([hidden]) {
				display: none;
			}
		`;
		this.shadowRoot.appendChild(style);
	}

	#getDisplayMode() {
		if (this.attrs.flex) {
			return this.attrs.inline ? 'inline-flex' : 'flex';
		}

		return this.attrs.inline ? 'inline-block' : 'block';
	}

	async #getScrollStyle() {
		const colors = Style.getColorsSet();
		if (this.attrs.xScroll === '' && this.attrs.yScroll === '') {
			return '';
		}

		return /* css */`
			:host {
				overflow: hidden;
				${this.attrs.xScroll !== '' ? `overflow-x: ${this.attrs.xScroll};` : ''}
				${this.attrs.yScroll !== '' ? `overflow-y: ${this.attrs.yScroll};` : ''}
			}

			:host {
				scrollbar-width: 12px;
				scrollbar-color: ${colors.bgDark2};

				@supports not selector(::-webkit-scrollbar-thumb) {
					scrollbar-width: thin;
					scrollbar-color: ${colors.bgLight1} ${colors.bgDark2};
				}
			}

			:host::-webkit-scrollbar-thumb {
				background-color: ${colors.bg};
				border: 1px solid ${colors.bgDark1};
				border-radius: 3px;
				box-shadow: 0 1px 1px 1px ${colors.bgLight1} inset;
			}

			:host::-webkit-scrollbar-track {
				background-color: ${colors.bgDark1};
				background-image: url("${await TexturesFactory.getVerticalLines(4, colors.bgDark2.cssValue)}");
				border-radius: 3px;
				box-shadow: 0 0 3px 1px ${colors.bgDark2} inset;
			}

			:host::-webkit-scrollbar {
				width: 12px;
				height: 12px;
			}
		`;
	}

	#appendContent() {
		if (!this.#initialNodes) {
			this.#initialNodes = [...this.childNodes];
		}

		let wrapper = this.getContentWrapper() || this.shadowRoot;

		for (const el of this.#initialNodes) {
			if (el instanceof HTMLTemplateElement) {
				wrapper.appendChild(this.#renderTemplate(el));
			} else {
				wrapper.appendChild(el);
			}
		}

		if (wrapper !== this.shadowRoot) {
			this.shadowRoot.appendChild(wrapper);
		}
	}

	/**
	 * @param {HTMLTemplateElement} tpl
	 * @return  {DocumentFragment}
	 */
	#renderTemplate(tpl) {
		if (this.attrs.range === '') {
			return tpl.content.cloneNode(true);
		}

		const tplOut = document.createElement('template');
		const tplHtml = tpl.innerHTML;
		let finalHtml = '';

		this.attrs.range.split(/,/).forEach((value, index) => {
			const replacedTemplates = [];
			let html = tplHtml.replace(/<template((?!<template).)+?<\/template>/ms, (html) => {
				const i = replacedTemplates.length;
				replacedTemplates.push(html);
				return `{##${i}##}`;
			});

			html = html.replaceAll(/{{value}}/g, value)
				.replaceAll(/data-template-style=/g, 'style=')
				.replaceAll(/{{index}}/g, `${index}`);

			let valuePath = '';
			for (let i = 0; i < replacedTemplates.length; i++) {
				valuePath += '../';

				html = html.replaceAll(/{##(\d+)##}/g, (_ , j) => {
					return replacedTemplates[parseInt(j)];
				});

				html = html.replaceAll(new RegExp(`{{${valuePath}value}}`, 'g'), value)
					.replaceAll(new RegExp(`{{${valuePath}index}}`, 'g'), `${index}`);
			}

			finalHtml += html;
		});

		tplOut.innerHTML = finalHtml;
		return tplOut.content;
	}
}

customElements.define('f-box', BoxElement);
