
import tpl from "./low-battery.handlebars";
import Template from "ui/Template";
import {i18n} from "ui/i18n";
import {MIN_ALLOWED_BATTERY_LEVEL} from "../../../config.js";

export async function createLowBatteryDialog() {
	const dialog = document.createElement('f-dialog');
	dialog.setAttribute('caption', i18n('title.low-battery'));
	dialog.setAttribute('width', '400');
	dialog.setAttribute('unclosable', 'true');
	dialog.setAttribute('color', 'warning');
	dialog.setAttribute('modal', '');
	dialog.innerHTML = new Template(tpl).createHtml({
		batteryLevel: MIN_ALLOWED_BATTERY_LEVEL * 100
	});

	document.body.appendChild(dialog);

	dialog.addEventListener('f-button-click', (e) => {
		dialog.remove();
	});

	return new Promise(r => {
		dialog.addEventListener('f-dialog-close', () => r());
	});
}
